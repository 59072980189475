import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Form, Input, Message, Button } from 'element-react';
import http from '../../../../utils/request';
import cookie from 'react-cookies';

class FillPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      disable: true,
      dataList: [
        {
          id: 0,
          name: '',
          pid: '',
        }
      ]
    }
  }

  // 添加
  addDomain() {

    const bool = this.checkData()

    if (!bool) {
      this.state.dataList.push({
        id: this.state.dataList.length,
        name: '',
        pid: '',
      });
    } else {
      Message({
        showClose: true,
        message: '请先填写信息后再添加',
        type: 'warning',
        customClass: 'msgpop'
      });
    }

    this.forceUpdate();
  }

  // 临时使用， 待优化
  componentWillReceiveProps(nextProps) {
    // console.log('change', nextProps.showVal)
    this.handleInitData(nextProps.showVal);
  }

  handleInitData(data) {
    const newData = [];
    data && data.length > 0 && this.setState({ value: '已填写' })
    data.map((item) => {
      newData.push({
        id: item.id,
        name: item.name,
        pid: item.web,
      });
    })

    this.setState({ dataList: newData, dataList2: newData });
  }

  // 删除 List Item
  handleDelete(index) {
    const listLength = this.state.dataList.length;
    if (listLength > 1) {
      this.state.dataList.splice(index, 1);
    }
    this.checkData();
    this.forceUpdate();
  }

  // 检查值是否为空
  checkData() {
    const { dataList } = this.state;
    let hasEmpty = false;
    dataList.map((item) => {
      if (item.id === '' || item.name === '' || item.pid === '') {
        hasEmpty = true;
        this.setState({ disable: true });
      } else {
        this.setState({ disable: false });
      }
    });

    return hasEmpty;
  }

  onChange(key, index, value) {
    this.state.dataList[index][key] = value;
    this.checkData();
    this.forceUpdate();
  }

  // 组织数据
  formatData() {
    const { dataList } = this.state;
    let datas = [];
    dataList.map((item) => {
      const val = item.name + ':' + item.pid;
      datas.push(val);
    });

    return datas
  }

  render() {

    const { title } = this.props;
    const { dataList, disable } = this.state;

    return (
      <div className="fill-popup">
        <Popup
          trigger={
            <Input readOnly placeholder="请输入您的社交媒体" value={this.state.value} />
          }
          modal
          nested
          lockScroll={true}
        >
          {close => (
            <div className="modal fill-popup">
              <button className="close" onClick={close}>
                &times;
              </button>

              <div className="header"> {title}</div>

              <div className="content">
                {
                  dataList && dataList.map((item, index) => {
                    return (
                      <Form labelPosition='right' labelWidth="100" key={index}>
                        <div className="delete-icon">
                          <p className="sub-title">{`${title} ${index + 1}`}</p>
                          {
                            this.state.dataList.length > 1 ? (
                              <i className="el-icon-delete" onClick={this.handleDelete.bind(this, index)}></i>
                            ) : null
                          }
                        </div>

                        <Form.Item label="平台名称：">
                          <Input value={dataList[index].name} onChange={this.onChange.bind(this, 'name', index)} />
                        </Form.Item>

                        <Form.Item label="主页地址/用户ID：">
                          <Input value={dataList[index].pid} onChange={this.onChange.bind(this, 'pid', index)} />
                        </Form.Item>
                      </Form>
                    )
                  })
                }
                <div className="add-btn">
                  <Button onClick={this.addDomain.bind(this)}>+添加</Button>
                </div>

              </div>

              <div className="actions">
                <Button
                  className="button"
                  onClick={() => {
                    close();
                  }}
                >
                  取消
                </Button>
                <Button
                  className="button"
                  disabled={disable}
                  onClick={() => {
                    const bool = this.checkData();
                    const token = cookie.load('token');
                    const string = this.formatData();

                    const postData = {
                      token,
                      type: 4,
                      string
                    }

                    if (!bool) {
                      http('post', 'member/pc/other', postData).then((res) => {
                        if (res.code === 200) {
                          this.setState({ value: "已填写" })
                          close();
                        } else {
                          close();
                        }
                      })
                    }
                  }}
                >
                  确认
                </Button>
              </div>
            </div>
          )}
        </Popup>
      </div>
    )
  }
}

export default connect(null, null)(FillPopup);
