import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { actionCreators } from './store';
import './index.scss';
import StepHead from '../../components/step-head/index';

import wechatLogo from '../../statics/assets/wechat.png';

class StepTwo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      countDown: 59,
      overdue: false,
      payState: false,
    }
  }

  componentWillReceiveProps (nextProps){
    if(nextProps.order_no!==this.props.order_no){
      this.checkPayStatus(nextProps.order_no)
    }
  }

  componentDidMount() {
    const { getPayData } = this.props;
    const token = cookie.load('token');
    token && getPayData({token});
    this.timeTranstion(60000);
  }

  componentWillUnmount(){
    clearInterval(this.timer)
  }

  // 倒计时
  timeTranstion(ms) {
    let maxtime = ms / 1000;
    let timer = null;
    let _this = this;
    this.setState({ overdue: false, payState: false });

    setTimeout(function f() {
      if (maxtime > 0) {
        let seconds = Math.floor(maxtime % 60);
        // seconds < 10 ? seconds = "0" + seconds : seconds = seconds;
        _this.setState({ countDown: seconds });
        --maxtime;
      } else {
        clearTimeout(timer);
        _this.setState({ countDown: 0, overdue: true });
        return;
      }

      timer = setTimeout(f, 1000);
    }, 1000)
  }

  // 轮训检查支付状态
  checkPayStatus(order_no){
    const { checkPay } = this.props;
    const phone = cookie.load('phone');
    const that = this;
    this.trainer = setInterval(()=>{
      const { status } = that.props;
      if(!status){
        checkPay({order_no})
      }else{
        // that.props.history.push('/step-3',{phone});
        that.props.history.push('/profile');
        clearInterval(that.trainer)
      }
    },2000)
  }

  getPayAgain = () => {
    const { getPayData } = this.props;
    const token = cookie.load('token');
    token && getPayData({token});
    this.timeTranstion(60000);
  }

  render() {

    const { countDown, overdue, payState } = this.state;
    const { phone, name, date, money, years, qrcode } = this.props;
    return (
      <div className="step-2">
        <div className="step-2__main">
          {/* common step */}
          <StepHead step={1} />

          <div className="step-2__des">
            <div className="user">
              <p className="span">购买账户:</p>
              <p className="val">{phone}</p></div>
            <div className="term">
              <p className="span">会员有效期:</p>
              <p className="val">{date}</p>
            </div>
            <div className="group">
              <p className="span">加入专委会:</p>
              <p className="val">{name}</p>
            </div>
            <div className="price">
              <p className="span">支付金额:</p>
              <p className="val">{money}元/{years}年<span></span></p>
            </div>
          </div>
          <div className="step-2__tip">
            <i className="iconfont"></i>
            <p className="des">会员有效期为支付成功后3～5年（根据专委会性质），支付后不可退款。</p>
          </div>

          <div className="step-2__pay">
            <div className="code-wrap">
              <div className="code-head">
                <img className="icon" src={wechatLogo} alt=""></img>
                <p className="title">微信支付</p>
              </div>
              <div className="code-body">
                <img className={`code ${overdue || payState ? 'overdue' : ''}`} src={qrcode} alt=""></img>
                {
                  payState ? (
                    <img className="payed" src="https://via.placeholder.com/79x79" alt=""></img>
                  ) : null
                }
              </div>
              {
                overdue ? (
                  <div className="count-down overdue">二维码已过期，请<span onClick={()=>this.getPayAgain()}>刷新</span>重新获取</div>
                ) : (
                  <div className="count-down">
                    {
                      payState ? (
                        <p><span>扫码成功</span>请在手机上完成付款</p>
                      ) : (
                        <p>距离二维码过期还剩<span>{countDown + 's'}</span>，过期后请刷新页面重新获取二维码</p>
                      )
                    }
                  </div>
                )
              }
            </div>
          </div>

        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  phone: state.getIn(['pay', 'phone']),
  name: state.getIn(['pay', 'name']),
  date: state.getIn(['pay', 'date']),
  money: state.getIn(['pay', 'money']),
  years: state.getIn(['pay', 'years']),
  qrcode: state.getIn(['pay', 'qrcode']),
  status: state.getIn(['pay', 'status']),
  order_no: state.getIn(['pay', 'order_no']),
});

const mapDispatch = (dispatch) => ({
  getPayData(data) {
    dispatch(actionCreators.getPayData(data));
  },
  checkPay(data){
    dispatch(actionCreators.checkPay(data))
  }
});

export default connect(mapState, mapDispatch)(StepTwo);