import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import Logo from '../../statics/assets/logo.svg';
import HeadIcon from '../../pages/profile/assets/yiwa.jpg';
import { withRouter } from 'react-router-dom';
import { actionCreators } from './store';
class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      stateVal: '退出',
      showOut: false
    }
  }

  handleClick() {
    const { changeUserState } = this.props;
    changeUserState(false);
    const { userState } = this.props;
    console.log('click userState', userState);
    this.props.history.push('/');
  }

  render() {

    const { stateVal } = this.state;
    const { userState } = this.props;

    return (
      <div className="header">
        <div className="header__wrap">
          <div className="header__wrap--item">
            <a href="http://scimea.cn/index.html#/" className="header__link pc">
              <img className="logo" src={Logo} alt=""></img>
            </a>
            <a href="#" className="header__link mobile">
              <img className="logo" src={Logo} alt=""></img>
            </a>
          </div>
          <div className="header__wrap--item">
            <a href="http://scimea.cn/index.html#/" className="header__link pc">了解更多<i className="el-icon-arrow-right"></i></a>
            <a href="http://public.scimea.cn" className="header__link mobile">了解更多<i className="el-icon-arrow-right"></i></a>
            {
              userState ? (
                <div className="header__status" onClick={this.handleClick.bind(this)}>
                  <img src={HeadIcon} alt="" className="headImg" />
                  <p className="status">{stateVal}</p>
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  userState: state.getIn(['user', 'userState'])
});

const mapDispatch = (dispatch) => ({
  changeUserState(flag) {
    dispatch(actionCreators.handleUserState(flag))
  }
});

export default withRouter(connect(mapState, mapDispatch)(Header));
