import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './store';
import Header from './common/header/index';
import Footer from './common/footer/index';
import SignUp from './pages/signUp/index';
import StepOne from './pages/step-1/index';
import StepTwo from './pages/step-2/index';
import StepThree from './pages/step-3/index';
import Privacy from './pages/privacy/index';
import Profile from './pages/profile/index';
import FormComponent from './pages/form/index';
import SubmitComponent from './pages/submit/index';
import 'element-theme-default';

import FrontendAuth from './frontendAuth';
import Share from './common/share'


const routes = [
  { path: '/', name: 'signUp', component: SignUp, auth: false },
  { path: '/step-1', name: 'step-1', component: StepOne, auth: true },
  { path: '/step-2', name: 'step-2', component: StepTwo, auth: true },
  { path: '/step-3', name: 'step-3', component: StepThree, auth: true },
  { path: '/privacy', name: 'privacy', component: Privacy, auth: false },
  { path: '/profile', name: 'profile', component: Profile, auth: true },
  { path: '/form', name: 'form', component: FormComponent, auth: true },
  { path: '/submit', name: 'submit', component: SubmitComponent, auth: true }
]

function App() {

  return (
    <Provider store={store}>
      <div>
        <Router>
          <Header></Header>
          <Share>
            <div className="common-content">
              <Switch>
                <FrontendAuth routerConfig={ routes } />
              </Switch>
            </div>
          </Share>
          <Footer></Footer>
        </Router>
      </div>
    </Provider>
  )
}


export default App;