import React, { Component } from 'react';
import { connect } from 'react-redux';

import StepHead from '../../components/step-head/index';

import wechatLogo from '../../statics/assets/wechat.png';

class Submit extends Component {
  constructor(props) {
    super(props)
  }

  toProfile() {
    this.props.history.push('/profile');
  }

  render() {
    return (

      <div className="step-3">
        <div className="step-3__main">
          <StepHead step={1} />

          <div className="step-3__state">
            <img className="img" src={wechatLogo} alt="" />
            <p className="title">提交成功</p>
            <p className="l-des">您的会员注册申请已提交，请使用<span>微信</span>扫描下方二维码审核结果会通过【SCIMEA会员中心】小程序发送给您</p>
            <img className="logo" src="https://api.mini.haimiantv.cn/member/common/qrcode.jpg" alt="" />
          </div>

          <div className="step-3__back">
            <button className="toHome" onClick={this.toProfile.bind(this)}>进入个人中心</button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, null)(Submit);