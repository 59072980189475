import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Input } from 'element-react';
import http from '../../../../utils/request';

class PopupComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: ''
    }
  }

  // 输入内容
  onChange(value) {
    this.setState({ value });
  }

  render() {

    const { desc } = this.props;

    return (
      <div className="popup">
        <Popup
          trigger={
            <p className="button"> {desc} </p>
          }
          modal
          nested
          lockScroll={true}
        >
          {close => (
            <div className="modal">
              <button className="close" onClick={close}>
                &times;
              </button>
              <div className="header"> 添加工作单位</div>
              <div className="content">
                <Input
                  onChange={this.onChange.bind(this)}
                  value={this.state.value}
                />
              </div>
              <div className="actions">
                <button
                  className="button"
                  onClick={() => {
                    close();
                  }}
                >
                  取消
                </button>
                <button
                  className="button"
                  onClick={() => {
                    http('post', 'member/index/organize_add', ApiSecurity.encryptParams({ "name": this.state.value })).then((res) => {
                      const result = JSON.parse(ApiSecurity.decrypt(res.data));
                      if (res.code === 200) {
                        this.props.getMsg(result);
                        close();
                      }
                    });
                  }}
                >
                  确认
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>
    )
  }
}

export default connect(null, null)(PopupComponent);