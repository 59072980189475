import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';

class Privacy extends Component {
  render() {
    return (
      <div className="privacy">
        <div className="privacy__main">
          <h1 className="privacy__main--title">SCIMEA会员协议条款</h1>
          <div className="privacy__main--box">
            <h2 className="privacy__main--sub-title">尊敬的SCIMEA会员：</h2>
            <p className="privacy__main--desc">欢迎您使用四川省国际医学交流促进会（英文名称SCIMEA，以下简称四川省医促会）会员注册服务。当您通过SCIMEA会员中心小程序付费购买或四川省医促会指定的其它方式升级成为四川省医促会会员后，您就可以享受四川省医促会提供的会员相关权益及服务。在成为四川省医促会会员之前，请您首先同意并接受以下服务条款。</p>
            <p className="privacy__main--desc">
              <span className="spe">【注意】</span> 四川省医促会有责任提醒您，请仔细阅读以下全部内容，如果您不同意本服务协议的任一或全部条款内容，请不要以确认的形式(包括但不限于点击)进行下一步操作或使用本协议项下服务。当您确认接受本服务协议或开始使用四川省医促会会员服务时，即表示您同四川省医促会已达成协议关系，自愿接受并遵守四川省医促会会员服务的所有服务协议。
            </p>
          </div>

          <div className="privacy__main--box">
            <h2 className="privacy__main--sub-title">1、 协议条款的确认和接受</h2>
            <p className="privacy__main--desc">1.1、 在您决定成为四川省医促会会员之前，请仔细阅读本协议条款。您应在完全同意以下条款的前提下，才能进行相应程序。 四川省医促会将根据以下协议条款为您提供四川省医促会会员服务(含另收费服务)。您在享受四川省医促会会员服务时，您与我单位应严格遵守本协议条款。 </p>
            <p className="privacy__main--desc">1.2、 四川省医促会根据品牌、服务的发展和中华人民共和国有关法律、法规的变化，不断地完善服务质量并依此调整服务条款，该调整会邀请您进行确认或在每年理事大会通过后生效。您所适用的购买程序、收费标准以及享有的会员权益等权利或义务，均以生效的新服务条款为准。 </p>
            <p className="privacy__main--desc">1.3、 您在成为四川省医促会会员过程中勾选[我同意]按钮即表示您已仔细阅读并完全接受本协议下的全部条款。只有您根据提示确认愿意、并支付相应对价完毕后，您才能享受四川省医促会会员服务。 </p>
            <p className="privacy__main--desc">1.4、 对于未明确授权给您的四川省医促会会员服务及其他的权利、您无法享有。</p>
            <p className="privacy__main--desc">1.5、 您是符合中华人民共和国法律规定的具有完全民事行为能力的自然人或如您属于限制性或无民事行为能力人，则您应在监护人同意或由监护人代替您进行相关操作，方可成为四川省医促会会员。</p>
            <p className="privacy__main--desc">1.6、 本协议中未约定事项以《四川省国际医学交流促进会章程》中的约定为准。</p>
          </div>

          <div className="privacy__main--box">
            <h2 className="privacy__main--sub-title">2、 风险及责任</h2>
            <p className="privacy__main--desc">2.1、 您应对自身在使用四川省医促会会员所提供的服务时的一切行为和结果(不论是否故意)负全部责任。</p>
            <p className="privacy__main--desc">2.2、 您应自行负责妥善且正确地保管、使用、维护其在四川省医促会全平台上的会员账户。您应对其账户采取必要和有效的保密措施。非四川省医促会原因致使您账户密码泄漏以及因您保管、使用、维护不当造成损失，除法律有明确规定外，四川省医促会无须承担与此有关的任何责任。</p>
            <p className="privacy__main--desc">2.3、 您明确了解并同意，如本协议第4.2条款约定的付费方式包含代收费运营商托收的付款方式，则您通过此种付费方式付费可能存在一定的商业风险(包括但不限于不法分子利用您账户或银行卡等有价卡等进行违法活动)，该等风险均会给您造成相应的经济损失。为此，您同意四川省医促会在充分履行其在本协议项下的义务及勤勉谨慎义务的前提下不对您的前述风险损失承担任何责任；并且，四川省医促会不承担不法分子对您造成的损失承担任何责任和义务。</p>
            <p className="privacy__main--desc">2.4、 成为四川省医促会会员后，您对本服务条款及修改有任何异议可与我们进行沟通，也可自动放弃四川省医促会会员资格，但该情形下不退还任何已缴纳的收费服务费用。</p>
          </div>

          <div className="privacy__main--box">
            <h2 className="privacy__main--sub-title">3、 收费标准</h2>
            <p className="privacy__main--desc">3.1、 一旦您点击购买了任意四川省医促会会员服务或其它付费服务并支付了款项的，即视为您认可该项会员服务标明之价格；您购买成功后，付费服务即时生效。</p>
            <p className="privacy__main--desc">3.2、 收费标准：具体四川省医促会会员服务及其它付费服务的收费标准以标价框或收银台显示的具体标价为准。</p>
          </div>

          <div className="privacy__main--box">
            <h2 className="privacy__main--sub-title">4、 付费购买四川省医促会会员步骤</h2>
            <p className="privacy__main--desc">4.1、 对于具体购买操作步骤，四川省医促会会在相关页面上做每一步的明确提示。</p>
            <p className="privacy__main--desc">4.2、 费用支付：购买的费用将在您点击购买之日按照服务标价框或收银台的标示价格通过四川省医促会已经接入且被允许的方式(目前仅接入并允许微信支付，未来可能开通支付宝、第三方支付、SCIMEA会员储值账户等)支付。 </p>
            <p className="privacy__main--desc">4.3、 一旦您在SCIMEA会员中心小程序购买四川省医促会会员成功后，则四川省医促会将即时收取该费用，除法律法规另有规定外，不提供退费服务。 </p>
            <p className="privacy__main--desc">4.4、工作人员会根据您提交的注册资料进行复核，若发现您不满足加入四川省医促会的条件，我们有权取消您的四川省医促会会员身份及权益。</p>
          </div>

          <div className="privacy__main--box">
            <h2 className="privacy__main--sub-title">5、四川省医促会会员权益</h2>
            <p className="privacy__main--desc">为改善用户体验、完善服务内容、四川省医促会提供符合其商业诉求的服务，相关服务、权益、功能、界面等更新、修改，包括开发新功能/权益、删除旧功能/权益等，您可按照变更后四川省医促会会员的实际情况继续使用相应的权益、功能或服务并适用本协议的相关规定。</p>
            <p className="privacy__main--desc">5.1、参与高端学术活动 <br/> 一旦您成为四川省医促会会员，在您的会员有效期间，您具备无限次参与医促会或专委会组织的重大学术活动资格，并且有机会代表医促会或专委会参加国际学术交流和出国考察活动。</p>
            <p className="privacy__main--desc">5.2、SCI优先约稿服务 <br/> 一旦您成为四川省医促会会员，在您的会员有效期间，您可以享受与医促会达成战略合作的SCI期刊优先约稿服务。</p>
            <p className="privacy__main--desc">5.3、承接专项科研课题 <br/> 一旦您成为四川省医促会会员，在您的会员有效期间，您可以优先承接医促会专项科研课题，并得到专业的国家科研课题申报解析等定制化咨询服务。</p>
            <p className="privacy__main--desc">5.4、享受有偿增值服务 <br/> 一旦您成为四川省医促会会员，在您的会员有效期间，您可以优惠价格取得医促会提供的各类有偿服务。如，专利申报、项目策划、会议咨询、 成果转化嫁接、风险管控等。 </p>
            <p className="privacy__main--desc">5.5、聘选各类专家评委 <br/> 一旦您成为四川省医促会会员，在您的会员有效期间，您可以优先被聘为各类评审、评价、咨询专家，并参与定期组织的专家会员专属交流活动。</p>
            <p className="privacy__main--desc">5.6、一对一专职助理 <br/> 一旦您成为四川省医促会会员，在您的会员有效期间，我们会为您配备一对一专职助理，为您提供个性化专属会员服务。</p>
          </div>

          <div className="privacy__main--box">
            <h2 className="privacy__main--sub-title">6、四川省医促会会员有效期</h2>
            <p className="privacy__main--desc">6.1、 您取得的四川省医促会会员有效期根据所加入的专委会(总会有效期为五年，普通专委会有效期为三年)，有效期届满未续费的您不再享受四川省医促会会员的权益，您一旦通过购买或其他四川省医促会认可的方式取得四川省医促会会员身份即视为认可它的有效期。</p>
            <p className="privacy__main--desc">6.2、 您可以通过付费或四川省医促会认可的方式延长您的四川省医促会会员有效期。</p>
          </div>

          <div className="privacy__main--box">
            <h2 className="privacy__main--sub-title">7、 四川省医促会会员须知</h2>
            <p className="privacy__main--desc">7.1、 为保护您的会员账户安全，避免共享账号或泄漏被盗，四川省医促会会员帐号仅限您个人使用，不允许转借或租赁他人；如果您利用四川省医促会会员账户用于刷单、倒卖(如商业代购)等涉嫌违法或可能对四川省医促会会员形象造成不良影响行为的，四川省医促会有权暂停或终止您的四川省医促会会员服务。</p>
          </div>

          <div className="privacy__main--box">
            <h2 className="privacy__main--sub-title">8、 变更及免责条款</h2>
            <p className="privacy__main--desc">8.1、 四川省医促会对四川省医促会会员的购买价格保留随时变更的权利(四川省医促会对会员不作保价承诺，价格变动导致的差价，四川省医促会无需向已购买会员的您退款差价，亦不会要求已购买会员的您补交差价)，新的四川省医促会会员价格在SCIMEA会员中心小程序予以显示，自显示起生效，但该价格标示有明显错误且四川省医促会发出相关价格错误通知的情形除外，该情况下双方应协商解决。</p>
            <p className="privacy__main--desc">8.2、 鉴于网络服务的特殊性，您同意四川省医促会有权随时变更、中断或终止部分或全部的网络服务。四川省医促会保留随时修改或中断服务的权利，并以公告形式通知。对于因此造成服务的中断或终止而造成的任何损失，除法律有明确规定外，四川省医促会无需对您或任何第三方承担任何责任。</p>
            <p className="privacy__main--desc">8.3、 对于因服务器的死机、网络的故障、数据库障、软件升级、 服务器维修、调整、升级等问题，其他不可抗拒的事甶，包括但不限于政府行为、自然灾害、黑客攻击等造成的服务中断和对您个人数据及资料造成的损失，除法律有明确规定外，四川省医促会不承担由此对您造成的任何损失或退还任何已缴纳的收费服务费用。但四川省医促会应尽可能事先进行通告。 </p>
            <p className="privacy__main--desc">8.4、 您的四川省医促会会员服务期限中包含解决故障、服务器维修、调整、升级等所需用的合理时间，对上述四川省医促会发出相关价格错误通知的情形除外，该情况下双方应协商解决。</p>
            <p className="privacy__main--desc">8.5、 四川省医促会对任何间接、偶然、特殊及继起的损害不负责任，不予赔偿。 </p>
            <p className="privacy__main--desc">8.6、 您在使用四川省医促会所提供的服务时，如遭受任何人身或财务的损失、损害或伤害，除法律有明确规定外，四川省医促会均不负责任。甶于您将个人账号告知他人或与他人共享会员帐户，甶此导致的任何个人资料泄露，四川省医促会不负任何责任。</p>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, null)(Privacy);
