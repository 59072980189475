import * as constants from './constants';
import http from '../../../utils/request';

const handleInitData = (result) => ({
  type: constants.GET_PROFILE,
  profile: result,
  comms: result.comms,
  head: result.head,
  person: result.person
});

export const getInitData = (obj) => {
  return (dispatch) => {
    http('post', 'member/pc/center', obj).then((res) => {
      if (res.code === 200) {
        const result = res.data;
        // console.log('data', result);
        dispatch(handleInitData(result));
      }
    });
  }
}