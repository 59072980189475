import * as constants from './constants';
import http from '../../../../../utils/request';

const handleUnit = (result) => ({
  type: constants.GET_UNIT,
  unit: result
});

const handleDepart = (result) => ({
  type: constants.GET_DEPART,
  department: result
})

export const getInitUnit = (obj) => {
  return (dispatch) => {
    http('post', 'member/index/organize', ApiSecurity.encryptParams(obj)).then((res) => {
      if (res.code === 200) {
        const result = JSON.parse(ApiSecurity.decrypt(res.data));
        // console.log(' organize result', result);
        dispatch(handleUnit(result));
      }
    })
  }
};

export const getInitDeparty = (obj) => {
  return (dispatch) => {
    http('post', 'member/index/department', ApiSecurity.encryptParams(obj)).then((res) => {
      if (res.code === 200) {
        const result = JSON.parse(ApiSecurity.decrypt(res.data));
        // console.log('depart result', result);
        dispatch(handleDepart(result));
      }
    })
  }
};