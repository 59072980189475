import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { actionCreators } from './store';
import StepHead from '../../components/step-head/index';
import { Form, Input, Radio, DatePicker, Select, Button, Upload, Message } from 'element-react';
import UploadComponent from './components/upload/index';
import Search from './components/search/index';
import FillPopup from './components/fill-popup/index';
import TechnicalPopup from './components/technical-popup/index';
import FillPopup1 from './components/fill-popup-1/index';
import FillPopup2 from './components/fill-popup-2/index';
import FillPopup3 from './components/fill-popup-3/index';
import cookies from 'react-cookies';
import http from '../../utils/request';
class StepOne extends Component {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        sort: null,            //专委会列表
        name: '',              //姓名
        phone: '18408218372',  //电话
        email: '',             //邮箱
        personId: '',          //身份证号
        photo: [],             //头像
        sex: '',               //性别
        date: null,            //出生日期
        _province: '',         //省份
        county: '',            //县城
        political: '',         //党派
        nation: '',            //民族
        education: '',         //最高学历
        degree: '',            //最高学位
        tutor: '',             //导师
        foreign: '',           //外语熟练度
        technology: [],        //技术职称
        technologyId: [],      //技术职称ID
        administrative: '',    //行政职务
        social: '',            //社会任职
        organize_id: '',       //工作单位
        department_id: '',     //科室部门
        resume_content: '',    //个人简历,
        fileList: '',          //上传列表
      },
      rules: {
        sort: [{ required: true, message: '请选择加入专委会', trigger: 'change' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }, {
          pattern: /^\w+@[a-z0-9]+\.[a-z]{2,4}$/,
          message: '请输入正确的邮箱格式'
        }],
        personId: [
          {
            required: true, message: '请填身份证号', trigger: 'blur'
          },
          {
            validator: (rule, code, callback) => {
              var city = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外 " };
              var tip = ""
              var pass = true
              if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
                tip = "身份证号格式错误"
                pass = false;
              } else if (!city[code.substr(0, 2)]) {
                // tip = "地址编码错误"
                tip = "身份证输入错误"
                pass = false
              } else {
                // 18位身份证需要验证最后一位校验位
                if (code.length === 18) {
                  code = code.split('')
                  // ∑(ai×Wi)(mod 11)
                  // 加权因子
                  var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
                  // 校验位
                  var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
                  var sum = 0
                  var ai = 0
                  var wi = 0
                  for (var i = 0; i < 17; i++) {
                    ai = code[i]
                    wi = factor[i]
                    sum += ai * wi
                  }
                  var last = parity[sum % 11];
                  if (parity[sum % 11] != code[17]) {
                    // tip = "校验位错误"
                    tip = "身份证输入错误"
                    pass = false
                  }
                }
              }
              if (!pass) {
                callback(new Error(tip))
              } else {
                callback()
              }
            }
          }
        ],
        photo: [{ type: "array", required: true, message: '请上传头像', trigger: 'change' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        date: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
        _province: [{ required: true, message: '请选择籍贯', trigger: 'change' }],
        county: [{ required: true, message: '请选择区县', trigger: 'change' }],
        nation: [{ required: true, message: '请选择民族', trigger: 'change' }],
        political: [{ required: true, message: '请选择党派', trigger: 'change' }],
        education: [{ required: true, message: "请选择最高学历", trigger: 'change' }],
        degree: [{ required: true, message: '请选择最高学位', trigger: 'change' }],
        foreign: [{ required: true, message: '请输入您的外语语种及等级', trigger: 'blur' }],
        technology: [{ required: true, message: '请选择技术职称', trigger: 'blur' }],
        administrative: [{ required: true, message: '请选择行政职务', trigger: 'change' }],
        social: [{ required: true, message: '请选择社会任职', trigger: 'change' }],
        organize_id: [{ required: true, message: '请选择工作单位', trigger: 'blur' }],
        department_id: [{ required: true, message: '请选择科室部门', trigger: 'blur' }],
        tutor: [{ required: true, message: '请选择导师', trigger: 'change' }]
      },
      value: [],               //
      fileList: [],            //上传简历
      _imageUrl: '',           //头像
      imgUrl: [],              //证书
      organize_name: '',       //单位名称
      department_name: '',     //科室部门名称,
      foreign_education: [],   //鉴于经历回显
      other_office: [],        //其他协会任职回显
      work_experience: [],     //工作经历回显
      social_media: [],        //社交媒体回显
    }
    this.uploadRef = React.createRef();
  }

  componentDidMount() {
    const { getInitData, getProcience, getInitCommon, getInitCommon2, detaile } = this.props;
    const phone = cookies.load('phone');
    this.fetchInitDetail({
      "token": cookies.load('token')
    });
    
    getInitData();
    getProcience({ "pid": 0 });
    getInitCommon();
    getInitCommon2();
    this.onChange('phone', phone);
  }

  fetchInitDetail(obj) {
    http('post', 'member/pc/detail', obj).then((res) => {
      if (res.code === 200) {
        const result = res.data;
        this.setInitData(result);
        console.log('result', result);
      }
    });
  }

  // 回显初始信息
  setInitData(datas) {

    if (datas && datas !== null) {
      datas.province_id && this.props.getInitCounty({ pid: datas.province_id });
  
      this.setState({
        _imageUrl: datas.photo,
        imgUrl: datas.qualification,
        organize_name: datas.organize_name,
        department_name: datas.department_name,
        foreign_education: datas.foreign_education,
        other_office: datas.other_office,
        work_experience: datas.work_experience,
        social_media: datas.social_media
      });
  
      const newForm = {
        sort: datas.comms.comm_id.toString(),
        name: datas.name,
        phone: datas.phone, 
        email: datas.email,
        personId: datas.idcard,
        photo: [datas.photo],
        sex: datas.gender.toString(),
        date: new Date(datas.birthday.toString()),
        _province: datas.province_id.toString(),  
        county: datas.city_id.toString(),
        political: datas.political.toString(),
        nation: datas.nation.toString(),
        education: datas.education.toString(),
        degree: datas.degree.toString(),
        tutor: datas.is_mentor.toString(),
        foreign: datas.foreign,
        technology: datas.technical_text,        //技术职称
        technologyId: datas.technical,      //技术职称ID
        administrative: datas.administrative.toString(),
        social: datas.social_service.toString(),
        organize_id: datas.organize_id.toString(),       //工作单位
        department_id: datas.department_id.toString(),     //科室部门
        resume_content: datas.resume_content,
        fileList: '',          //上传列表
      }
  
      console.log('new form', newForm);
      this.setState({
        form: newForm
      });
      this.forceUpdate();
    }
  }

  // 取消注册
  cancleRegister() {
    this.props.history.push('/');
  }

  // 处理日期格式
  handleDate(date) {
    let formatDate = '';
    let _year = new Date(date).getFullYear();
    let _month = (new Date(date).getMonth() + 1);
    let _day = new Date(date).getDate();
    _month = _month < 9 ? '0' + _month : _month;
    _day = _day < 9 ? '0' + _day : _day;
    formatDate = _year + '-' + _month + '-' + _day;
    return formatDate;
  }

  // 获取子组件的信息
  handleGetChildInfo(flag) {
    const { form } = this.state;

    if (flag.type === 'unit') {
      form.organize_id = flag.id.toString();
      this.refs.form.validateField('organize_id');
    } else {
      form.department_id = flag.id.toString();
      this.refs.form.validateField('department_id');
    }

    this.setState({
      form
    });
  }

  // 上传简历
  handleChange(file, fileList) {
    this.setState({ fileList: fileList.slice(-3) });
  }

  // 证件照上传
  handleAvatarScucess(res, file) {
    const self = this;
    if (res.code === 200) {
       const url = res.data.url;
      setTimeout(() => {
        self.setState({ _imageUrl: url });
        self.onChange('photo', [url]);
      }, 500);
    } else {
      Message({
        showClose: true,
        message: res.msg,
        type: 'error'
      });
    }
  }

  // 头像上传失败
  handleAvatarErr(err, file) {
    console.log('err', err)
  }

  beforeAvatarUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      Message('上传头像图片大小不能超过 2MB!');
    }
    const { form } = this.state;
    const { photo } = form;
    photo.push(file.path);
    return isLt2M;
  }

  nextStep() {
    const { form } = this.state;
    const postData = {
      token: cookies.load('token'),
      comm_ids: form.sort,
      name: form.name,
      email: form.email,
      phone: form.phone,
      photo: form.photo.toString(),
      idcard: form.personId,
      gender: form.sex,
      birthday: this.handleDate(form.date),
      province_id: form._province,
      city_id: form.county,
      nation: form.nation,
      political: form.political,
      education: form.education,
      degree: form.degree,
      is_mentor: form.tutor,
      foreign: form.foreign,
      technical: form.technologyId.toString(),
      administrative: form.administrative,
      social_service: form.social,
      organize_id: form.organize_id,
      department_id: form.department_id,
      resume_content: form.resume_content
    };

    this.refs.form.validate((valid) => {
      if (valid && form.social !== '' && form.organize_id !== '') {
        http('post', 'member/pc/register', postData).then((res) => {
          if (res.code === 200) {
            // this.props.history.push('/step-2');
            this.props.history.push('/submit');
          } else {
            Message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
          }
        });
      } else {
        Message({
          showClose: true,
          message: '请正确填写表单信息',
          type: 'error'
        });
      }
    });
  }

  checkPersonId(val) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    return reg.test(val);
  }

  onChange(key, value) {
    const self = this;
    this.setState({
      form: Object.assign({}, this.state.form, { [key]: value })
    });

    if (key === 'personId' && value.length >= 15) {
      this.checkPersonId(value) && this.queryInfo(value);
    } else if (key === '_province') {
      this.props.getInitCounty({ pid: value });
      setTimeout(() => {
        const { form } = self.state;
        form.county = '';
        self.setState({ form });
      }, 0);
    }
  }

  // 根据身份证号获取生日和性别
  queryInfo(idcard) {
    http('post', 'member/pc/idcard', { idcard }).then((res) => {
      if (res.code === 200) {
        const result = res.data;
        this.onChange('date', new Date(result.birthday));
        this.onChange('sex', result.gender.toString());
      }
    });
  }

  changeTechnical(item, flag) {
    const { form } = this.state;
    form.technology = item;
    form.technologyId = flag;
    this.setState({
      form
    });
  }

  render() {
    const { sort, province, county, unit, nation, political, education,
      degree, technology, administrative, social, department, detaile } = this.props;
    const { _imageUrl, fileList, imgUrl, foreign_education, other_office,
      work_experience, social_media } = this.state;
    const token = cookies.load('token');

    return (
      <div className="step-1">
        <div className="step-1__main">
          <StepHead step={0} />

          <Form model={this.state.form} ref="form" labelPosition='left' labelWidth="100" rules={this.state.rules}>

            {/* 加入专委会 */}
            <Form.Item label="加入专委会:" prop="sort">
              <Select value={this.state.form.sort} clearable={true} onChange={this.onChange.bind(this, 'sort')} placeholder="请选择专委会" >
                {
                  sort && sort.map((item) => {
                    return <Select.Option key={item.get('id')} label={item.get('name')} value={item.get('id').toString()} />
                  })
                }
              </Select>
            </Form.Item>

            {/* 姓名 */}
            <Form.Item label="姓名:" prop="name">
              <Input value={this.state.form.name} onChange={this.onChange.bind(this, 'name')} placeholder="请输入姓名"></Input>
            </Form.Item>

            {/* 手机号 */}
            <div className="user-phone">
              <Form.Item label="手机号:" prop="phone">
                <Input value={this.state.form.phone} disabled></Input>
              </Form.Item>
            </div>

            {/* 电子邮箱 */}
            <Form.Item label="电子邮箱:" prop="email">
              <Input value={this.state.form.email} onChange={this.onChange.bind(this, 'email')} placeholder="请输入邮箱地址"></Input>
            </Form.Item>

            {/* 身份证号 */}
            <Form.Item label="身份证号:" prop="personId">
              <Input value={this.state.form.personId} onChange={this.onChange.bind(this, 'personId')} placeholder="请输入身份证号"></Input>
            </Form.Item>

            {/* 证件照 */}
            <div className="photo">
              <Form.Item label="证件照：" prop="photo">
                <Upload
                  ref="uploadRef"
                  className="avatar-uploader"
                  data={{ token }}
                  showFileList={false}
                  fileList={this.state.form.photo}
                  listType="picture"
                  action='https://api.mini.haimiantv.cn/member/pc/file'
                  onSuccess={(res, file) => this.handleAvatarScucess(res, file)}
                  beforeUpload={file => this.beforeAvatarUpload(file)}
                  onError={(err, file) => thsi.handleAvatarErr(err, file)}
                >
                  {_imageUrl ? <img src={_imageUrl} className="avatar" /> : <i className="el-icon-plus avatar-uploader-icon"></i>}
                </Upload>
              </Form.Item>
            </div>

            {/* 性别 */}
            <Form.Item label="性别:" prop="sex">
              <Radio.Group value={this.state.form.sex} onChange={this.onChange.bind(this, 'sex')}>
                <Radio value="1" >男</Radio>
                <Radio value="2" >女</Radio>
                <Radio value="0" >其他</Radio>
              </Radio.Group>
            </Form.Item>

            {/* 出生日期 */}
            <div className="date-choose">
              <Form.Item label="出生日期:" prop="date" required={true}>
                <DatePicker
                  value={this.state.form.date}
                  placeholder="请选择出生日期"
                  onChange={this.onChange.bind(this, 'date')}
                />
              </Form.Item>
            </div>

            {/* 籍贯 */}
            <div className="linkage">
              <div className="linkage__item">
                <Form.Item label="籍贯:" prop="_province">
                  <Select value={this.state.form._province} placeholder="省市" onChange={this.onChange.bind(this, '_province')}>
                    {
                      province && province.map((item) => {
                        return <Select.Option key={item.get('val')} label={item.get('des')} value={item.get('val').toString()} />
                      })
                    }
                  </Select>
                </Form.Item>
              </div>

              <div className="linkage__item county">
                <Form.Item prop="county">
                  <Select value={this.state.form.county} clearable={true} placeholder="县区" onChange={this.onChange.bind(this, 'county')}>
                    {
                      county && county.map((item) => {
                        return <Select.Option key={item.get('val')} label={item.get('des')} value={item.get('val').toString()} />
                      })
                    }
                  </Select>
                </Form.Item>
              </div>
            </div>

            {/* 民族 */}
            <Form.Item label="民族:" prop="nation">
              <Select value={this.state.form.nation} clearable={true} placeholder="请选择民族" onChange={this.onChange.bind(this, 'nation')}>
                {
                  nation && nation.map((item) => {
                    return <Select.Option key={item.get('val')} label={item.get('des')} value={item.get('val').toString()} />
                  })
                }
              </Select>
            </Form.Item>

            {/* 党派 */}
            <Form.Item label="党派:" prop="political">
              <Select value={this.state.form.political} clearable={true} placeholder="请选择党派" onChange={this.onChange.bind(this, 'political')}>
                {
                  political && political.map((item) => {
                    return <Select.Option key={item.get('val')} label={item.get('des')} value={item.get('val')} />
                  })
                }
              </Select>
            </Form.Item>

            {/* 最高学历 */}
            <Form.Item label="最高学历:" prop="education">
              <Select value={this.state.form.education} clearable={true} placeholder="请选择最高学历" onChange={this.onChange.bind(this, 'education')}>
                {
                  education && education.map((item) => {
                    return <Select.Option key={item.get('val')} label={item.get('des')} value={item.get('val')} />
                  })
                }
              </Select>
            </Form.Item>

            {/* 最高学位 */}
            <Form.Item label="最高学位:" prop="degree">
              <Select value={this.state.form.degree} clearable={true} placeholder="请选择最高学位" onChange={this.onChange.bind(this, 'degree')}>
                {
                  degree && degree.map((item) => {
                    return <Select.Option key={item.get('val')} label={item.get('des')} value={item.get('val')} />
                  })
                }
              </Select>
            </Form.Item>

            {/* 导师 */}
            <Form.Item label="是否导师:" prop="tutor">
              <Radio.Group value={this.state.form.tutor} onChange={this.onChange.bind(this, 'tutor')}>
                <Radio value="0">否</Radio>
                <Radio value="2">研究生导师</Radio>
                <Radio value="1">博士生导师</Radio>
              </Radio.Group>
            </Form.Item>

            {/* 外语熟练度 */}
            <Form.Item label="外语熟练度:" prop="foreign">
              <Input
                placeholder="请输入您的外语语种及等级"
                value={this.state.form.foreign}
                onChange={this.onChange.bind(this, 'foreign')}
              ></Input>
            </Form.Item>

            {/* 技术职称 */}
            <Form.Item label="技术职称:" prop="technology">
              <TechnicalPopup title="技术职称" renderData={this.state.form.technology} dataList={technology.toJS()} changeTechnical={this.changeTechnical.bind(this)} />
            </Form.Item>

            {/* 行政职务 */}
            <Form.Item label="行政职务:" prop="administrative">
              <Select value={this.state.form.administrative} onChange={this.onChange.bind(this, 'administrative')} clearable={true} placeholder="请选择您的行政职务">
                {
                  administrative && administrative.map((item) => {
                    return <Select.Option key={item.get('val')} label={item.get('des')} value={item.get('val')} />
                  })
                }
              </Select>
            </Form.Item>

            {/* 社会任职 */}
            <Form.Item label="社会任职:" prop="social">
              <Select value={this.state.form.social} onChange={this.onChange.bind(this, 'social')} clearable={true} placeholder="请选择您的社会任职">
                {
                  social && social.map((item) => {
                    return <Select.Option key={item.get('val')} label={item.get('des')} value={item.get('val')} />
                  })
                }
              </Select>
            </Form.Item>

            {/* 工作单位 */}
            <Form.Item label="工作单位" prop="organize_id">
              <Search label="工作单位" showVal={this.state.organize_name} type="unit" datas={unit} getChildInfo={this.handleGetChildInfo.bind(this)} />
            </Form.Item>

            {/* 科室部门 */}
            <Form.Item label="科室部门" prop="department_id">
              <Search label="科室部门" showVal={ this.state.department_name } type="department" datas={department} getChildInfo={this.handleGetChildInfo.bind(this)} />
            </Form.Item>

            {/* 教育经历 */}
            <Form.Item label="教育经历:">
              <FillPopup title="教育经历" showVal={ foreign_education } />
            </Form.Item>

            {/* 其他学会任职 */}
            <Form.Item label="其他学会任职:">
              <FillPopup1 title="其他学会任职" showVal={ other_office } />
            </Form.Item>

            {/* 工作经历 */}
            <Form.Item label="工作经历:">
              <FillPopup2 title="工作经历" showVal={ work_experience } />
            </Form.Item>

            {/* 个人简历 */}
            <Form.Item label="个人简历:">
              <Input
                value={this.state.form.resume_content}
                placeholder="请输入个人简历"
                onChange={this.onChange.bind(this, 'resume_content')}
                type="textarea"
                autosize={{ minRows: 4, maxRows: 4 }}
              />

              <Upload
                className="upload-resume"
                data={{ token, "real": "on", "name": "personfile" }}
                action="https://api.mini.haimiantv.cn/member/pc/file"
                onChange={(file, fileList) => this.handleChange(file, fileList)}
                fileList={fileList}
                limit={1}
              >
                <Button type="primary">+点击上传</Button>
              </Upload>
            </Form.Item>

            {/* 资格证书 */}
            <Form.Item label="资格证书:">
              <div className="certificate">
                <UploadComponent imgUrl={imgUrl} desc="医师资格证" index="1" />
                <UploadComponent imgUrl={imgUrl} desc="医师执业证" index="2" />
                <UploadComponent imgUrl={imgUrl} desc="职称证书" index="3" />
                <UploadComponent imgUrl={imgUrl} desc="工作证" index="4" />
              </div>
            </Form.Item> 

            {/* 社交媒体 */}
            <Form.Item label="社交媒体:">
              <FillPopup3 title="社交媒体" showVal={ social_media } />
            </Form.Item>

            {/* 底部按钮 */}
            <Form.Item className="btn-box">
              <div className="app__btns">
                <Button className="cancle" onClick={this.cancleRegister.bind(this)}>取消</Button>
                <Button type="primary" className="next" onClick={this.nextStep.bind(this)}>下一步</Button>
              </div>
            </Form.Item>
          </Form>

        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  sort: state.getIn(['app', 'sort']),
  province: state.getIn(['app', 'province']),
  county: state.getIn(['app', 'county']),
  famous: state.getIn(['app', 'famous']),
  nation: state.getIn(['app', 'nation']),
  political: state.getIn(['app', 'political']),
  education: state.getIn(['app', 'education']),
  degree: state.getIn(['app', 'degree']),
  technology: state.getIn(['app', 'technicals']),
  social: state.getIn(['app', 'social']),
  administrative: state.getIn(['app', 'administrative']),
  unit: state.getIn(['search', 'unit']),
  department: state.getIn(['search', 'department']),
  detaile: state.getIn(['app', 'detaile']),    // get the detail data to set form data
});

const mapDispatch = (dispatch) => ({
  getInitData() {
    dispatch(actionCreators.getInitData());
  },

  getProcience(obj) {
    dispatch(actionCreators.getInitProvince(obj));
  },

  getInitCounty(obj) {
    dispatch(actionCreators.getInitCounty(obj));
  },

  getInitCommon() {
    dispatch(actionCreators.getInitCommon());
  },

  getInitCommon2() {
    dispatch(actionCreators.getInitCommon2());
  },
});

export default connect(mapState, mapDispatch)(StepOne);
