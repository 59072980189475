import { fromJS } from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  sort: [],
  province: [],
  county: [],

  nation: [],
  political: [],
  education: [],
  degree: [],

  technicals: [],
  administrative: [],
  social: [],

  detaile: [],
});

const getInitSort = (state, action) => {
  return state.merge({
    sort: fromJS(action.sort)
  });
}

const getInitProvince = (state, action) => {
  return state.merge({
    province: fromJS(action.province)
  });
}

const getInitCounty = (state, action) => {
  return state.merge({
    county: fromJS(action.county)
  });
}

const getInitCommon = (state, action) => {
  return state.merge({
    nation: fromJS(action.nation),
    political: fromJS(action.political),
    education: fromJS(action.education),
    degree: fromJS(action.degree),
  });
}

const getInitCommon2 = (state, action) => {
  return state.merge({
    technicals: fromJS(action.technicals),
    administrative: fromJS(action.administrative),
    social: fromJS(action.social)
  });
}

const getInitDetail = (state, action) => {
  return state.merge({
    detaile: fromJS(action.detaile)
  });
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case constants.GET_SORT:
      return getInitSort(state, action);
    case constants.GET_PROVINCE:
      return getInitProvince(state, action);
    case constants.GET_COUNTRY:
      return getInitCounty(state, action);
    case constants.GET_SECOND:
      return getInitCommon(state, action);
    case constants.GET_THIRD:
      return getInitCommon2(state, action);
    case constants.GET_INIT:
      return getInitDetail(state, action);
    default:
      return state;
  }
}