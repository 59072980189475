import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import cookie from 'react-cookies';
class FrontendAuth extends Component {
  render() {
    const { routerConfig, location } = this.props;
    const { pathname } = location;
    const isLogin = cookie.load("token");
    const signStatus = cookie.load("signStatus");
    // 如果该路由不用进行权限校验，登录状态下登陆页除外
    // 这部分代码，是为了在非登陆状态下，访问不需要权限校验的路由
    const targetRouterConfig = routerConfig.find(
      (item) => {
        return item.path.replace(/\s*/g, "") === pathname
      }
    );

    if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
      const { component } = targetRouterConfig;
      return <Route exact path={pathname} component={component} />
    }

    console.log('登陆状态', isLogin);
    if (isLogin) {
      // 如果是登陆状态，想要跳转到登陆，重定向到主页
      if (pathname === "/") {
        return (<Route path={pathname} component={targetRouterConfig.component} />);
      } else {
        // 如果路由合法且已经注册过
        if (signStatus
          && signStatus === 'y'
          && pathname !== '/profile'
          && pathname !== '/form'
          && pathname !== '/step-1'
          && pathname !== '/step-2'
          && pathname !== '/step-3'
          && pathname !== '/submit'
        ) {
          console.log('如果路由合法且已经注册过');
          return <Redirect to="/" />
        }
        // 如果路由合法，就跳转到相应的路由
        if (targetRouterConfig) {
          console.log('如果路由合法，就跳转到相应的路由', targetRouterConfig);
          return (<Route path={pathname} component={targetRouterConfig.component} />);
        } else {
          // 如果路由不合法，重定向到 404 页面
          console.log('路由不合法');
          // return <Redirect to="/404" />;
        }
      }
    } else {
      // 非登陆状态下，当路由合法时且需要权限校验时，跳转到登陆页面，要求登陆
      // console.log('非登陆状态');
      if (targetRouterConfig && targetRouterConfig.auth) {
        return <Redirect to="/" />;
      } else {
        // 非登陆状态下，路由不合法时，重定向至 404
        console.log('路由不合法');
        // return <Redirect to="/404" />;
      }
    }
  }
}
export default FrontendAuth