import * as constants from './constants';
import http from '../../../utils/request';
import { Message } from 'element-react';

const formLink = (result) => ({
  type: constants.GET_FROM,
  link: result
});

export const handleFromData = (obj) => {
  return (dispatch) => {
    http("post", "/member/pc/printf", obj).then((res) => {
      if (res.code === 200) {
        const result = res.data.img;
        dispatch(formLink(result));
      } else {
        Message({
          type: 'error',
          message: res.msg,
          duration: 3000,
        });
      }
    });
  }
}