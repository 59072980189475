import { fromJS } from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  userState: false
});

const handleUserState = (state, action) => {
  return state.merge({
    userState: fromJS(action.userState)
  });
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case constants.USER_STATE:
      return handleUserState(state, action);
    default:
      return state;
  }
}