import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Input } from 'element-react';

class TechnicalPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      technicalValue: '',
      dataList: [],
      healthData: {}, // 卫生技术人员
      healthChildren: [],
      highSchoolTeach: {}, // 高等学校教师
      sciResearch: {}, // 科学研究人员
      nonMediStaff: {}, // 非医疗人员
      techChecked: {
        health: '',
        healthChild: '',
        healthChildText: '',
        highSchool: '',
        highSchoolText: '',
        sci: '',
        sciText: '',
        nonMe: '',
        nonMeText: '',
      }, // 选择的技术职称
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.dataList !== state.dataList) {
      return {
        dataList: props.dataList,
        healthData: props.dataList[0] || {},
        highSchoolTeach: props.dataList[1] || {},
        sciResearch: props.dataList[2] || {},
        nonMediStaff: props.dataList[3] || {},
      }
    }
    return null;
  }

  // 选择技术职称
  selectTechnical = (type, key, text) => {
    let {
      techChecked,
      healthData,
      healthChildren
    } = this.state;
    // 点击卫生技术人员
    if (type === 'TA') {
      // 选择了卫生技术人员则无法选择非医疗人员
      techChecked.nonMe = ''
      techChecked.nonMeText = ''
      techChecked.healthChild = ''
      techChecked.healthChildText = ''
      // 再次点击已选择的则取消选择
      if (techChecked.health === key) {
        techChecked.health = ''
        techChecked.healthChild = ''
        techChecked.healthChildText = ''
        healthChildren = []
      } else {
        techChecked.health = key;
        if (key === 'T_A') {
          healthChildren = healthData.data[0]
        } else if (key === 'T_B') {
          healthChildren = healthData.data[1]
        } else if (key === 'T_C') {
          healthChildren = healthData.data[2]
        } else if (key === 'T_D') {
          healthChildren = healthData.data[3]
        }
      }
    } else if (type === 'TB') { // 点击高等学校教师
      // 再次点击已选择的则取消选择
      if (techChecked.highSchool === key) {
        techChecked.highSchool = ''
        techChecked.highSchoolText = ''
      } else {
        techChecked.highSchool = key;
        techChecked.highSchoolText = text;
      }
    } else if (type === 'TC') { // 点击科学研究人员
      // 再次点击已选择的则取消选择
      if (techChecked.sci === key) {
        techChecked.sci = ''
        techChecked.sciText = ''
      } else {
        techChecked.sci = key;
        techChecked.sciText = text;
      }
    } else if (type === 'TD') { // 点击非医疗人员
      // 选择了非医疗人员则无法选择卫生技术人员
      techChecked.health = ''
      techChecked.healthChild = ''
      techChecked.healthChildText = ''
      healthChildren = []
      // 再次点击已选择的则取消选择
      if (techChecked.nonMe === key) {
        techChecked.nonMe = ''
        techChecked.nonMeText = ''
      } else {
        techChecked.nonMe = key;
        techChecked.nonMeText = text;
      }
    }
    this.setState({
      techChecked,
      healthChildren
    })
  }

  // 选择卫生技术人员二级
  selectHealChild = (key, text) => {
    let {
      techChecked
    } = this.state;
    if (techChecked.healthChild === key) {
      techChecked.healthChild = '';
      techChecked.healthChildText = '';
    } else {
      techChecked.healthChild = key;
      techChecked.healthChildText = text;
    }
    this.setState({
      techChecked
    })
  }

  // 技术职称弹窗确认按钮
  confirmTechTit = () => {
    const { techChecked } = this.state;
    const technicalArr = []
    const technicalIdArr = []
    let technical = '', technicalId = '';

    if (techChecked.healthChildText && techChecked.healthChild) {
      technicalArr.push(techChecked.healthChildText)
      technicalIdArr.push(techChecked.healthChild)
    }
    if (techChecked.highSchoolText && techChecked.highSchool) {
      technicalArr.push(techChecked.highSchoolText)
      technicalIdArr.push(techChecked.highSchool)
    }
    if (techChecked.sciText && techChecked.sci) {
      technicalArr.push(techChecked.sciText)
      technicalIdArr.push(techChecked.sci)
    }
    if (techChecked.nonMeText && techChecked.nonMeText) {
      technicalArr.push(techChecked.nonMeText)
      technicalIdArr.push(techChecked.nonMe)
    }
    if (technicalArr.length > 0) {
      technical = technicalArr.join('/')
      technicalId = technicalIdArr.join(',')
    }

    const { changeTechnical } = this.props;
    this.setState({
      technicalValue: technical,
      technicalId,
    });

    changeTechnical(technical, technicalId);
  }

  render() {

    const { title, renderData } = this.props;
    const {
      technicalValue,
      healthData,
      techChecked,
      healthChildren,
      highSchoolTeach,
      sciResearch,
      nonMediStaff
    } = this.state;

    return (
      <div className="fill-popup">
        <Popup
          trigger={
            <Input value={ technicalValue || renderData} placeholder="请选择技术职称" />
          }
          modal
          nested
          lockScroll={true}
        >
          {close => (
            <div className="modal fill">
              <button className="close" onClick={close}>
                &times;
              </button>

              <div className="header"> {title}</div>

              <div className="content">
                {/* <!-- 卫生技术人员 --> */}
                <div className="healthTech">
                  <div className="content__text">卫生技术人员：</div>
                  <div className="content__first">
                    {
                      healthData.data && healthData.data.map(item => {
                        return (
                          <div key={item.val} className="content__out__box">
                            <div className={techChecked.health === item.val ? 'content__box first-checked' : 'content__box'} onClick={() => { this.selectTechnical(healthData.val, item.val, item.des) }}>{item.des}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                  {/* <!-- 二级选项 --> */}
                  <div className="content__second">
                    {
                      healthChildren.data && healthChildren.data.map((item, index) => {
                        return (
                          <div key={item.val} className="content__out__box">
                            <div className={techChecked.healthChild === item.val ? 'content__box__second second-checked' : 'content__box__second'} onClick={() => { this.selectHealChild(item.val, item.des) }}>{item.des}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {/* <!-- 高等学校教师 --> */}
                <div className="content-list">
                  <div className="content__text">高等学校教师</div>
                  <div className="content__first">
                    {
                      highSchoolTeach.data && highSchoolTeach.data.map(item => {
                        return (
                          <div key={item.val} className="content__out__box">
                            <div className={techChecked.highSchool === item.val ? 'content__box first-checked' : 'content__box'} onClick={() => { this.selectTechnical(highSchoolTeach.val, item.val, item.des) }}>{item.des}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {/* <!-- 科学研究人员 --> */}
                <div className="content-list">
                  <div className="content__text">科学研究人员</div>
                  <div className="content__first">
                    {
                      sciResearch.data && sciResearch.data.map(item => {
                        return (
                          <div key={item.val} className="content__out__box">
                            <div className={techChecked.sci === item.val ? 'content__box first-checked' : 'content__box'} onClick={() => { this.selectTechnical(sciResearch.val, item.val, item.des) }}>{item.des}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {/* <!-- 非医疗人员 --> */}
                <div className="content-list">
                  <div className="content__text">非医疗人员</div>
                  <div className="content__first">
                    {
                      nonMediStaff.data && nonMediStaff.data.map(item => {
                        return (
                          <div key={item.val} className="content__out__box">
                            <div className={techChecked.nonMe === item.val ? 'content__box first-checked' : 'content__box'} onClick={() => { this.selectTechnical(nonMediStaff.val, item.val, item.des) }}>{item.des}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>

              <div className="actions">
                <button
                  className="button"
                  onClick={() => {
                    close();
                  }}
                >
                  取消
                </button>
                <button
                  className="button"
                  onClick={() => {
                    this.confirmTechTit();
                    close()
                  }}
                >
                  确认
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>
    )
  }
}

export default connect(null, null)(TechnicalPopup);
