import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { Popover } from 'element-react';
import personImg from '../../assets/lianxiren.svg';
import phoneImg from '../../assets/phone.svg';
import kefuImg from '../../assets/kefu.svg';
import codeImg from '../../assets/code.svg';

class FloatComponent extends Component {
  render() {
    return (
      <div className="float">
        <ul className="float__group">
          <li className="float__group--item">
            <Popover placement="left" width="220" trigger="hover" content={
              (
                <div className="contact">
                  <div className="contact-item">
                    <div className="type">
                      <img className="icon" src={ phoneImg } alt="" />
                      <p className="key">客服热线：</p>
                    </div>
                    <p className="val">028-80592559</p>
                  </div>
                  <div className="contact-item">
                    <div className="type">
                      <img className="icon" src={ personImg } alt="" />
                      <p className="key">联系人：</p>
                    </div>
                    <p className="val">19113901604</p>
                  </div>
                </div>
              )
            }>
              <img className="icon" src={ kefuImg } alt="" />
            </Popover>
          </li>
          <li className="float__group--item">
            <Popover placement="left" width="220" trigger="hover" content={
              (
                <div className="mini-code">
                  <img className="code" src="https://api.mini.haimiantv.cn/member/common/qrcode.jpg" alt="" />
                  <p className="desc">绑定微信，及时获取审核结果</p>
                </div>
              )
            }>
              <img className="icon" src={ codeImg } alt="" />
            </Popover>
          </li>
        </ul>
      </div>
    )
  }
}

export default connect(null, null)(FloatComponent);
