import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GlobalStyled } from './style.js';
import { GlobalStyled1 } from './statics/iconfont/iconfont';
import './reset.scss';

var font=document.documentElement.clientWidth/3.75
document.documentElement.style.fontSize=font+'px'

const NewApp = (props) => {
  return (
    <Fragment>
      <GlobalStyled />
      <GlobalStyled1 />
      <App />
    </Fragment>
  )
}

ReactDOM.render(
  <NewApp />,
  document.getElementById('root')
);
