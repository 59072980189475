import { fromJS } from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  phone: '',
  name: '',
  date: '',
  money: '',
  years: '',
  qrcode: '',
  order_no: '',
  status: 0,
});

const handleGetPay = (state, action) => {
  return state.merge({
    phone: fromJS(action.phone),
    name: fromJS(action.name),
    date: fromJS(action.date),
    money: fromJS(action.money),
    years: fromJS(action.years),
    qrcode: fromJS(action.qrcode),
    order_no: fromJS(action.order_no),
  });
}

const handleCheckPay = (state, action) => {
  return state.merge({
    status: fromJS(action.status),
  });
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.GET_PAY:
      return handleGetPay(state, action);
    case constants.CHECK_PAY:
      return handleCheckPay(state, action)
    default:
      return state;
  }
}