import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import http from '../utils/request';
import wx from 'weixin-js-sdk'
class Share extends Component {
  componentDidUpdate(prevProps) {
    // if (this.props.location !== prevProps.location) {
    //   const meeting_id = localStorage.getItem('meeting_id')

    //   if (meeting_id) {
    //     this.getShareSetting({ meeting_id })
    //   }
    // }
  }
  componentDidMount() {
    this.getJsSdkSignatureHttp({ url: window.location.href });
    this.props.history.listen((e) => {
      this.getJsSdkSignatureHttp({ url: window.location.href });
    });
    wx.error((res) => {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log(res)
    });
  }

  // 完整的url链接
  getJsSdkSignatureHttp = (data) => { //获取jssdk签名
    //签名的路径一定不能修改，要取完整的url路径，而且路径？后面不能有特殊的字符，需要用encodeURIComponent方法处理一下
    http('post', '/member/pc/signature', data).then((res) => {
      if (res.code === 200) {
        const { appid, time, noncestr, signature, img, url, title } = res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: appid, // 必填，公众号的唯一标识
          timestamp: time, // 必填，生成签名的时间戳
          nonceStr: noncestr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名，见附录1
          jsApiList: [
            'onMenuShareAppMessage',
            'onMenuShareTimeline',
            'updateAppMessageShareData',
            'updateTimelineShareData',
            // 微信新版1.4.0暂不支持安卓，故这里要两个接口一起调用下
          ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });

        wx.ready(() => {
          //分享给朋友
          wx.onMenuShareAppMessage(
            {
              title: 'SCIMEA会员注册', // 分享标题
              desc: title, // 分享描述
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: img, // 分享图标
              success: function () {
                console.log('success')
                // 用户确认分享后执行的回调函数
              },
            }
          );
          // wx.updateAppMessageShareData({...data, desc: this.shareDesc});

          //分享到朋友圈
          wx.onMenuShareTimeline(
            {
              title: 'SCIMEA会员注册', // 分享标题
              desc: title, // 分享描述
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: img, // 分享图标
              success: function () {
                console.log('success')
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // console.log('cancel')
                // 用户取消分享后执行的回调函数
              }
            }
          );
          // wx.updateTimelineShareData(data);
        });
      }
    }).catch(e => {
      console.log(e);
    });
  };

  render() {
    return this.props.children
  }
}
export default withRouter(Share);