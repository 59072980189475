import {
  createGlobalStyle
} from 'styled-components';

export const GlobalStyled1 = createGlobalStyle`
  @font-face {
    font-family: 'iconfont';  /* project id 2211870 */
    src: url('//at.alicdn.com/t/font_2211870_vdzkqc4zbj7.eot');
    src: url('//at.alicdn.com/t/font_2211870_vdzkqc4zbj7.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_2211870_vdzkqc4zbj7.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_2211870_vdzkqc4zbj7.woff') format('woff'),
    url('//at.alicdn.com/t/font_2211870_vdzkqc4zbj7.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_2211870_vdzkqc4zbj7.svg#iconfont') format('svg');
  }

  .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

`