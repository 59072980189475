import { fromJS } from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  signState: false,
  smsCode: '',
  token: '',
  msg: '',
});

const handleSignUp = (state, action) => {
  return state.merge({
    signData: fromJS(action.signState),
    smsCode: fromJS(action.smsCode),
    token: fromJS(action.token),
    msg: fromJS(action.msg)
  });
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case constants.SIGN_UP:
      return handleSignUp(state, action);
    case constants.GET_SMS:
      return handleSignUp(state, action);
    default:
      return state;
  }
}