import { fromJS } from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  link: '',
});

const handleData = (state, action) => {
  return state.merge({
    link: fromJS(action.link)
  });
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case constants.GET_FROM:
      return handleData(state, action);
    default:
      return state;
  }
}