import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { Popover, Dialog } from 'element-react';

class ClickImg extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogVisible: false
    }
  }

  render() {

    const { desc, photo } = this.props;

    const headImg = photo ? photo : 'http://placehold.it/300x300/0f0/ccc.png';

    return (
      <div className="click-img">
        <img className="click-img__small" onClick={() => this.setState({ dialogVisible: true })} src={headImg} alt="" />
        {
          desc ? (
            <p className="click-img__desc">{desc}</p>
          ) : null
        }

        <Dialog
          title=""
          size="small"
          visible={this.state.dialogVisible}
          onCancel={() => this.setState({ dialogVisible: false })}
        >
          <Dialog.Body>
            <img className="click-img__big" src={headImg} alt="" />
          </Dialog.Body>

        </Dialog>


        {/* <Popover placement="right" width="500" trigger="hover" content={
          (
            <img className="click-img__big" src={ headImg } alt="" />
          )
        }>
          <div>
            <img className="click-img__small" src={ headImg } alt="" />
            {
              desc ? (
                <p className="click-img__desc">{ desc }</p>
              ) : null
            }
          </div>
        </Popover> */}
      </div>
    )
  }
}

export default connect(null, null)(ClickImg);
