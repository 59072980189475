import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { actionCreators } from './store';
import './index.scss';
import StepHead from '../../components/step-head/index';
import payLogo from '../../statics/assets/paysuccess.png';
import { Redirect } from 'react-router-dom';
class StepThree extends Component {
  
  componentDidMount(){
    const { getCodeUrl } = this.props;
    const phone = this.props.location.state && this.props.location.state.phone;
    const token = cookie.load('token');
    phone && token && getCodeUrl({token});
    this.resetParmas();
  }

  backToHome() {
    window.location.href = 'http://scimea.cn/index.html#/';
  }

  resetParmas() {
    cookie.remove('token');
    cookie.remove('phone');
    cookie.remove('signStatus');
  }

  render() {
    const { codeImg } = this.props;
    const phone = this.props.location.state && this.props.location.state.phone;
    if (!phone) {
      return <Redirect to="/" />
    }
    return (
      <div className="step-3">
        <div className="step-3__main">
          <StepHead step={2} />

          <div className="step-3__state">
            <img className="img" src={payLogo} alt="" />
            <p className="title">支付成功</p>
            <p className="l-des">您的会员注册申请已提交，请使用<span>微信</span>扫描下方二维码审核结果会通过【SCIMEA会员中心】小程序发送给您</p>
            <img className="logo" src={codeImg} alt="" />
          </div>

          <div className="step-3__back">
            <button className="toHome" onClick={this.backToHome.bind(this)}>返回首页</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  codeImg: state.getIn(['paysuccess', 'codeImg']),
});

const mapDispatch = (dispatch) => ({
  getCodeUrl(data) {
    dispatch(actionCreators.getCodeUrl(data));
  },
});

export default connect(mapState, mapDispatch)(StepThree);