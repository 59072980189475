import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { actionCreators as profileActionCreators } from '../profile/store';
import { actionCreators } from './store';
import cookies from 'react-cookies';
import http from '../../utils/request';

class FormComponent extends Component {

  componentDidMount() {
    const { getProfileData, getFormData } = this.props;
    const obj = {
      token: cookies.load('token')
    };

    getFormData(obj);
    getProfileData(obj);
  }

  clickDownLoad() {
    const { link } = this.props;
    window.location.href = 'https://api.mini.haimiantv.cn/member/pc/do_printf?file=' + link;
  }

  render() {

    const { person, comms, link } = this.props;

    return (
      <div className="form">
        <div className="form__main">
          <table className="form__table" width="100%" style={{ tableLayout: 'fixed' }}>
            <thead className="form__table-head">
              <tr>
                <td className="title" colSpan='8'>四川省国际医学交流促进会</td>
              </tr>
              <tr>
                <td className="sub-title" colSpan='8'>入会申请表</td>
              </tr>
              <tr >
                <td colSpan="8" ></td>
              </tr>
              <tr >
                <td colSpan="8" ></td>
              </tr>
            </thead>

            <tbody className="form__table-body">
              <tr className="form__content">
                <td className="fontStyl">姓名</td>
                <td>{person.get('name')}</td>
                <td className="fontStyl">性别</td>
                <td>{person.get('gender_text')}</td>
                <td className="fontStyl">出生日期</td>
                <td>{person.get('birthday')}</td>
                <td rowSpan="4" colSpan="2" className="img-box">
                  <img className="headImg" src={person.get('photo')} alt="" />
                </td>
              </tr>

              <tr className="form__content">
                <td className="fontStyl">身份证号</td>
                <td colSpan="3">{person.get('idcard')}</td>
                <td className="fontStyl">手机号</td>
                <td>{person.get('phone')}</td>
              </tr>
              <tr className="form__content">
                <td className="fontStyl">邮箱</td>
                <td colSpan="3">{person.get('email')}</td>
                <td className="fontStyl">籍贯</td>
                <td>{person.get('area')}</td>
              </tr>
              <tr className="form__content">
                <td className="fontStyl">民族</td>
                <td>{person.get('nation')}</td>
                <td className="fontStyl">党派</td>
                <td>{person.get('political_text')}</td>
                <td className="fontStyl">最高学历</td>
                <td>{person.get('education')}</td>
              </tr>
              <tr className="form__content">
                <td className="fontStyl">最高学位</td>
                <td>{person.get('degree')}</td>
                <td className="fontStyl">是否导师</td>
                <td>{person.get('is_mentor')}</td>
                <td className="fontStyl">外语熟练度</td>
                <td colSpan="3">{person.get('foreign')}</td>
              </tr>
              <tr className="form__content">
                <td className="fontStyl">技术职称</td>
                <td>{person.get('technical')}</td>
                <td className="fontStyl">行政职务</td>
                <td>{person.get('administrative')}</td>
                <td className="fontStyl">社会任职</td>
                <td colSpan="3">{person.get('social_service')}</td>
              </tr>
              <tr className="form__content">
                <td className="fontStyl">工作单位</td>
                <td colSpan="3">{person.get('organize_name')}</td>
                <td className="fontStyl">科室部门</td>
                <td colSpan="3">{person.get('department_name')}</td>
              </tr>
              <tr className="form__content">
                <td className="fontStyl">国家</td>
                <td className="fontStyl" colSpan="2">进修/留学机构</td>
                <td className="fontStyl" colSpan="2">毕业院校</td>
                <td className="fontStyl" colSpan="2">就读时间</td>
                <td className="fontStyl" >获得学历</td>
              </tr>
              {
                person
                  && person.get('foreign_education')
                  ? person.get('foreign_education').map((item, index) => {
                    return (
                      <tr className="form__content" key={index}>
                        <td>{item.get('country')}</td>
                        <td colSpan="2">{item.get('mechanism')}</td>
                        <td colSpan="2">{item.get('graduation')}</td>
                        <td colSpan="2">{item.get('time')}</td>
                        <td>{item.get('education')}</td>
                      </tr>
                    )
                  }) : (
                    <tr className="form__content">
                      <td></td>
                      <td colSpan="2"></td>
                      <td colSpan="2"></td>
                      <td colSpan="2"></td>
                      <td></td>
                    </tr>
                  )
              }

              <tr className="form__content">
                <td className="fontStyl" rowSpan={person && person.get('other_office') ? person.get('other_office').toJS().length + 1 : 4}>其他学会任职</td>
                <td className="fontStyl" colSpan="3">学会名称</td>
                <td className="fontStyl">学会类型</td>
                <td className="fontStyl" colSpan="1">担任职务</td>
                <td className="fontStyl" colSpan="2">任职时间</td>
              </tr>
              {
                person
                  && person.get('other_office') ?
                  person.get('other_office').map((item, index) => {
                    return (
                      <tr className="form__content" key={index}>
                        <td colSpan="3">{item.get('name')}</td>
                        <td>{item.get('type')}</td>
                        <td>{item.get('job')}</td>
                        <td colSpan="2">{item.get('start') + '~' + item.get('end')}</td>
                      </tr>
                    )
                  }) : (
                    <Fragment>
                      <tr className="form__content">
                        <td colSpan="3"></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2"></td>
                      </tr>
                      <tr className="form__content">
                        <td colSpan="3"></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2"></td>
                      </tr>
                      <tr className="form__content">
                        <td colSpan="3"></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2"></td>
                      </tr>
                    </Fragment>
                  )
              }

              <tr className="form__content">
                <td className="fontStyl" rowSpan={person && person.get('work_experience') ? person.get('work_experience').toJS().length + 1 : 4}>工作经历</td>
                <td className="fontStyl" colSpan="3">工作单位</td>
                <td className="fontStyl" colSpan="2">职务/职称</td>
                <td className="fontStyl" colSpan="2">工作时间</td>
              </tr>
              {
                person
                  && person.get('work_experience')
                  ? person.get('work_experience').map((item, index) => {
                    return (
                      <tr className="form__content" key={index}>
                        <td colSpan="3">{item.get('name')}</td>
                        <td colSpan="2">{item.get('job')}</td>
                        <td colSpan="2">{item.get('start') + '~' + item.get('end')}</td>
                      </tr>
                    )
                  }) : (
                    <Fragment>
                      <tr className="form__content">
                        <td colSpan="3"></td>
                        <td colSpan="2"></td>
                        <td colSpan="2"></td>
                      </tr>
                      <tr className="form__content">
                        <td colSpan="3"></td>
                        <td colSpan="2"></td>
                        <td colSpan="2"></td>
                      </tr>
                      <tr className="form__content">
                        <td colSpan="3"></td>
                        <td colSpan="2"></td>
                        <td colSpan="2"></td>
                      </tr>
                    </Fragment>
                  )
              }

              <tr className="form__content">
                <td className="fontStyl" rowSpan="4">
                  <p className="people-title">个人简介</p>
                </td>
                <td rowSpan="4" colSpan="7">
                  <p className="people-val">{person.get('resume_content')}</p>
                </td>
              </tr>
              <tr className="form__content">
              </tr>
              <tr className="form__content">
              </tr>
              <tr className="form__content">
              </tr>

              {
                comms && comms.toJS().length > 0 ?
                  comms.map((item, index) => {
                    return (
                      <tr className="form__content" key={item.get('id')}>
                        {
                          index === 0 ? (
                            <td className="fontStyl" colSpan="2" rowSpan={comms.toJS().length}>申请参加何专业委员会</td>
                          ) : null
                        }
                        <td>{index + 1}</td>
                        <td colSpan="5">{item.get('name')}</td>
                      </tr>
                    )
                  }) : (
                    <Fragment>
                      <tr className="form__content">
                        <td className="fontStyl" colSpan="2" rowSpan="4">申请参加何专业委员会</td>
                        <td>1</td>
                        <td colSpan="5"></td>
                      </tr>
                      <tr className="form__content">
                        <td>2</td>
                        <td colSpan="5"></td>
                      </tr>
                      <tr className="form__content">
                        <td>3</td>
                        <td colSpan="5"></td>
                      </tr>
                      <tr className="form__content">
                        <td>4</td>
                        <td colSpan="5"></td>
                      </tr>
                    </Fragment>
                  )
              }

              <tr className="form__content">
                <td rowSpan="3" colSpan="2">
                  <p className="fontStyl">审核意见</p>
                  <div className="value"></div>
                </td>
                <td rowSpan="3" colSpan="3">
                  <p className="fontStyl">单位意见</p>
                  <div className="value">
                    <div className="box">
                      <p className="log">盖章</p>
                      <p className="time">
                        <span className='val'>年</span>
                        <span className='val'>月</span>
                        <span className='val'>日</span>
                      </p>
                    </div>
                  </div>
                </td>
                <td rowSpan="3" colSpan="3">
                  <p className="fontStyl">四川省国际医学交流促进会意见</p>
                  <div className="value">
                    <div className="box">
                      <p className="log">盖章</p>
                      <p className="time">
                        <span className='val'>年</span>
                        <span className='val'>月</span>
                        <span className='val'>日</span>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="form__content"></tr>
              <tr className="form__content"></tr>
            </tbody>
          </table>

          <p className="application">申请人：</p>
          <p className="time">
            <span className="val">年</span>
            <span className="val">月</span>
            <span className="val">日</span>
          </p>

          {
            link ? (
              <div className="print-box">
                {/* <a className="print" target="_blank" download="申请表" href={link}>打印申请表</a> */}
                <button className="print" onClick={this.clickDownLoad.bind(this)}>打印申请表</button>
              </div>
            ) : null
          }

        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  profile: state.getIn(['profile', 'profile']),
  comms: state.getIn(['profile', 'comms']),
  head: state.getIn(['profile', 'head']),
  person: state.getIn(['profile', 'person']),
  link: state.getIn(['form', 'link']),
});

const mapDispatch = (dispatch) => ({
  getProfileData(obj) {
    dispatch(profileActionCreators.getInitData(obj));
  },

  getFormData(obj) {
    dispatch(actionCreators.handleFromData(obj));
  }
});

export default connect(mapState, mapDispatch)(FormComponent);