import {
  fromJS
} from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  unit: [],
  department: []
});

const getInitUnit = (state, action) => {
  return state.merge({
    unit: fromJS(action.unit)
  });
}

const getInitDepart = (state, action) => {
  return state.merge({
    department: fromJS(action.department)
  });
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case constants.GET_UNIT:
      return getInitUnit(state, action);
    case constants.GET_DEPART:
      return getInitDepart(state, action);
    default:
      return state;
  }
}