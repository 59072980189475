import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import ClickImg from './component/click-img/index';
import HeadImg from './assets/yiwa.jpg';
import FloatComponent from './component/float/index';
import { actionCreators } from './store';
import cookies from 'react-cookies';
import http from '../../utils/request';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { Message } from 'element-react';
class Profile extends Component {

  componentDidMount() {
    const { getProfileData } = this.props;
    const obj = {
      token: cookies.load('token')
    };

    getProfileData(obj);
  }

  handleClickIcon(flag) {
    // console.log('flag', flag.get('type'));
    const _type = flag.get('type');

    switch (_type) {
      case 'print':
        return this.handlePrint();
      case 'edit':
        return this.handleEdit();
      case 'download':
        return this.handleDownLoad();
      case 'pay':
        return this.handlePay();
      default:
        return
    }
  }

  handlePrint() {
    this.props.history.push('/form');
  }

  handleEdit() {
    this.props.history.push('/step-1');
  }

  handlePay() {
    this.props.history.push('/step-2');
  }

  handleDownLoad() {
    const { comms } = this.props;
    const _data = comms.toJS();

    for (let i = 0; i < _data.length; i++) {
      const obj = {
        'token': cookies.load('token'),
        'id': _data[i].id
      }
      this.fetchBook(obj);
    }
  }

  fetchBook(obj) {
    const self = this;
    http('post', '/member/pc/download', obj).then((res) => {
      if (res.code === 200) {
        const reuslt = res.data.img;
        console.log('resuilt', reuslt);
        self.downloadFile(reuslt);
      } else {
        Message({
          type: 'error',
          message: res.msg,
          duration: 3000,
        });
      }
    })
  }

  downloadFile(url) {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";  // 防止影响页面
    iframe.style.height = 0;  // 防止影响页面
    iframe.src = 'https://api.mini.haimiantv.cn/member/pc/do_printf?file=' + url; 
    document.body.appendChild(iframe);  // 这一行必须，iframe挂在到dom树上才会发请求
    setTimeout(()=>{
      iframe.remove();
    }, 2 * 60 * 1000);
  }

  render() {

    const { comms, head, person } = this.props;

    return (
      <div className="profile">
        <div className="profile__head">
          <div className="profile__head--wrap">
            <div className="profile__head--item">
              <div className="profile__head--img-box">
                <img className="profile__head--img" src={HeadImg} alt="" />
              </div>
              <div className="profile__head--desc">
                <p className="name">{person.get('name')}</p>
                <p className="desc">{head.get('des')}</p>
              </div>
            </div>
            <div className="profile__head--item">
              {
                head && head.get('button') && head.get('button').map((item, index) => {
                  return (
                    <div className="profile__head--fucn-box" key={index}>
                      {
                        item.get('to') ? (
                          <div className="profile__head--fucn">
                            <p className="desc">{item.get('name')}</p>
                            <Link to={item.get('to')} target="_blank">
                              <img className="icon" src={item.get('icon_w')} alt="" />
                              <img className="icon hover" src={item.get('icon')} alt="" />
                            </Link>
                          </div>
                        ) : (
                          <div className="profile__head--fucn" onClick={this.handleClickIcon.bind(this, item, comms)}>
                            <p className="desc">{item.get('name')}</p>
                            <Fragment>
                              <img className="icon" src={item.get('icon_w')} alt="" />
                              <img className="icon hover" src={item.get('icon')} alt="" />
                            </Fragment>
                          </div>
                        )
                      }
                      <div className="profile__head--fucn">
                        <span className="line"></span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="profile__main">
          <div className="profile__section">
            <h2 className="profile__title">加入的专委会</h2>
            <ul className="profile__group">
              {
                comms.map((item) => {
                  return (
                    <li key={item.get('id')} className="profile__group--item">{item.get('name')}</li>
                  )
                })
              }
            </ul>
          </div>

          <div className="profile__section">
            <h2 className="profile__title">基本资料</h2>
            <ul className="profile__group">
              <li className="profile__group--item">
                <div className="type">姓名：</div>
                <p className="val">{person.get('name')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">手机号：</div>
                <p className="val">{person.get('phone')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">电子邮箱：</div>
                <p className="val">{person.get('email')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">身份证号：</div>
                <p className="val">{person.get('idcard')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">证件照：</div>
                <div className="val">
                  <ClickImg photo={person.get('photo')} />
                </div>
              </li>
              <li className="profile__group--item">
                <div className="type">性别：</div>
                <p className="val">{person.get('gender')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">出生日期：</div>
                <p className="val">{person.get('birthday')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">籍贯：</div>
                <p className="val">{person.get('area')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">民族：</div>
                <p className="val">{person.get('nation')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">党派：</div>
                <p className="val">{person.get('political_text')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">最高学历：</div>
                <p className="val">{person.get('education')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">最高学位：</div>
                <p className="val">{person.get('degree')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">是否导师：</div>
                <p className="val">{person.get('is_mentor')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">外语熟练度：</div>
                <p className="val">{person.get('foreign')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">技术职称：</div>
                <p className="val">{person.get('technical')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">行政职务：</div>
                <p className="val">{person.get('administrative')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">社会任职：</div>
                <p className="val">{person.get('social_service')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">工作单位：</div>
                <p className="val">{person.get('organize_name')}</p>
              </li>
              <li className="profile__group--item">
                <div className="type">科室部门：</div>
                <p className="val">{person.get('department_name')}</p>
              </li>
            </ul>
          </div>

          <div className="profile__section">
            <h2 className="profile__title">其他资料</h2>
            <div className="profile__section--child">
              {
                person && person.get('foreign_education') && person.get('foreign_education').map((item, index) => {
                  return (
                    <div className="exprence" key={index}>
                      <h3 className="title">教育经历 {index + 1}</h3>
                      <ul className="profile__group">
                        <li className="profile__group--item">
                          <div className="type">国家：</div>
                          {
                            item.get('country') ? (
                              <p className="val">{item.get('country')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }

                        </li>
                        <li className="profile__group--item">
                          <div className="type">进修/留学机构：</div>
                          {
                            item.get('mechanism') ? (
                              <p className="val">{item.get('mechanism')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                        <li className="profile__group--item">
                          <div className="type">毕业院校：</div>
                          {
                            item.get('graduation') ? (
                              <p className="val">{item.get('graduation')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                        <li className="profile__group--item">
                          <div className="type">就读时间：</div>
                          {
                            item.get('time') ? (
                              <p className="val">{item.get('time')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                        <li className="profile__group--item">
                          <div className="type">获得学历：</div>
                          {
                            item.get('education') ? (
                              <p className="val">{item.get('education')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                      </ul>
                    </div>
                  )
                })
              }
            </div>
            <div className="profile__section--child">
              {
                person && person.get('other_office') && person.get('other_office').map((item, index) => {
                  return (
                    <div className="exprence" key={index}>
                      <h3 className="title">其他学会任职 {index + 1}</h3>
                      <ul className="profile__group">
                        <li className="profile__group--item">
                          <div className="type">学会名称：</div>
                          {
                            item.get('name') ? (
                              <p className="val">{item.get('name')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                        <li className="profile__group--item">
                          <div className="type">学会类型：</div>
                          {
                            item.get('type') ? (
                              <p className="val">{item.get('type')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                        <li className="profile__group--item">
                          <div className="type">担任职务：</div>
                          {
                            item.get('job') ? (
                              <p className="val">{item.get('job')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                        <li className="profile__group--item">
                          <div className="type">任职时间：</div>
                          {
                            item.get('start') && item.get('end') ? (
                              <p className="val">{item.get('start') + '~' + item.get('end')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                      </ul>
                    </div>
                  )
                })
              }
            </div>
            <div className="profile__section--child">
              {
                person && person.get('work_experience') && person.get('work_experience').map((item, index) => {
                  return (
                    <div className="exprence" key={index}>
                      <h3 className="title">工作经历 {index + 1}</h3>
                      <ul className="profile__group">
                        <li className="profile__group--item">
                          <div className="type">工作单位：</div>
                          {
                            item.get('name') ? (
                              <p className="val">{item.get('name')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                        <li className="profile__group--item">
                          <div className="type">职务/职称：</div>
                          {
                            item.get('job') ? (
                              <p className="val">{item.get('job')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                        <li className="profile__group--item">
                          <div className="type">工作时间：</div>
                          {
                            item.get('start') && item.get('end') ? (
                              <p className="val">{item.get('start') + '~' + item.get('end')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                      </ul>
                    </div>
                  )
                })
              }
            </div>
            <div className="profile__section--child">
              <div className="exprence">
                <h3 className="title">个人简历</h3>
                {
                  person && person.get('resume_content') ? (
                    <p className="l-desc">{person.get('resume_content')}</p>
                  ) : (
                    <p className="l-desc">未填写</p>
                  )
                }

              </div>
            </div>
            <div className="profile__section--child">
              <div className="exprence">
                <h3 className="title">资格证书</h3>
                <ul className="profile__list">
                  {
                    person && person.get('qualification') && person.get('qualification').map((item, index) => {
                      return (
                        <li className="profile__list--item" key={index}>
                          <ClickImg desc={item.get('name')} photo={item.get('url')} />
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
            <div className="profile__section--child">
              {
                person && person.get('social_media') && person.get('social_media').map((item, index) => {
                  return (
                    <div className="exprence" key={index}>
                      <h3 className="title">社交媒体 {index + 1}</h3>
                      <ul className="profile__group">
                        <li className="profile__group--item">
                          <div className="type">平台：</div>
                          {
                            item.get('name') ? (
                              <p className="val">{item.get('name')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                        <li className="profile__group--item">
                          <div className="type">网址：</div>
                          {
                            item.get('web') ? (
                              <p className="val">{item.get('web')}</p>
                            ) : (
                              <p className="val">未填写</p>
                            )
                          }
                        </li>
                      </ul>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <FloatComponent />
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  profile: state.getIn(['profile', 'profile']),
  comms: state.getIn(['profile', 'comms']),
  head: state.getIn(['profile', 'head']),
  person: state.getIn(['profile', 'person']),
});

const mapDispatch = (dispatch) => ({
  getProfileData(obj) {
    dispatch(actionCreators.getInitData(obj));
  }
});

export default connect(mapState, mapDispatch)(Profile);
