import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { Form, Input, Button, Checkbox, Popover, Message } from 'element-react';
import banner from './img/banner.jpg';
import mBanner from './img/m-banner.jpg';
import phoneUrl from './img/phone.svg';
import wechat from './img/wechat.svg';
import { actionCreators } from './store';
import { actionCreators as  headerActionCreators } from '../../common/header/store';
import http from '../../utils/request';
import cookie from 'react-cookies';
import step from './img/step.jpg';
import step1 from './img/step-1.jpg';
import company from './img/company.jpg';
import mCompany from './img/m-company.jpg';
import bottomBanner from './img/bottom-banner.jpg';

class SignUp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        phone: '',
        code: '',
        type: []
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9]|191)[0-9]{8}$/,
            message: '请输入有效的手机号！'
          },
        ]
      },
      _disable: false,
      showFrom: true,
      countDown: "获取验证码",
      signState: true,
      content: "隐私协议隐私协议隐私协议隐私协议隐私协议隐",
      isMobile: false,
      bodyContent: [{
        'id': 1,
        'title': '高效率注册',
        'des': '极低的注册成本让您高效、轻松地完成会员申请',
        'url': step
      }, {
        'id': 2,
        'title': '多方位权益',
        'des': '科学的权益归属，满足您不同阶段的科研/临床需求',
        'url': step1
      }]
    };
  }

  // 重置登陆状态和token
  resetState() {
    cookie.remove('token');
    cookie.remove('phone');
    cookie.remove('signStatus');
  }

  componentDidMount() {
    this.resetState();
    !this.isPc() && this.setState({ showFrom: false, isMobile: true });
  }

  // 切换登陆的方式
  handleSwith() {
    const { showFrom } = this.state;
    this.setState({ showFrom: !showFrom });
  }

  // 点击注册按钮
  handleSignUp() {
    const self = this;
    const { phone, code } = this.state.form;
    const { changeUserState } = this.props;
    http('post', 'member/pc/check_phone', { phone, code }).then((res) => {
      if (res.code === 200) {
        cookie.save('token', res.data.token);
        cookie.save('phone', phone);
        console.log('state change');
        changeUserState(true);
        if (res.data.to === 'center') {
          self.props.history.push('/profile');
        } else if (res.data.to === 'register') {
          self.props.history.push('/step-1');
        }
      } else {
        Message({
          type: 'error',
          message: res.msg,
          duration: 3000,
        });
      }
    })
  }

  // 点击获取验证码
  handleGetCode() {
    const { getSms } = this.props;
    const { phone, code } = this.state.form;
    getSms({ phone });
    this.timeTranstion(60000);
  }

  // 监听注册按钮的状态
  handleSignBtnState() {
    const { phone, code, type } = this.state.form;
    if (phone.length === 11 && code.length === 6 && type.length > 0) {
      this.setState({ signState: false });
    } else {
      this.setState({ signState: true });
    }
  }

  // 倒计时
  timeTranstion(ms) {
    let maxtime = ms / 1000;
    let timer = null;
    let _this = this;

    setTimeout(function f() {
      if (maxtime > 0) {
        let seconds = Math.floor(maxtime % 60) || 60;
        _this.setState({ countDown: seconds + `s后重新获取`, _disable: true });
        --maxtime;
      } else {
        clearTimeout(timer);
        _this.setState({ countDown: '重新获取', _disable: false });
        return;
      }

      timer = setTimeout(f, 1000);
    }, 1000)
  }

  // 检查手机号是否已注册
  checkPhone(phone) {
    const obj = { "phone": phone };
    http('post', 'member/pc/check', obj).then((res) => {
      if (res.code === 200) {
        cookie.save("signStatus", res.data.status);
      }
    });
  }

  // 检查是否是移动端
  isPc() {
    const userAgentInfo = navigator.userAgent;
    const Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  // 立即加入，回到顶部
  handleClickJoin() {
    document.documentElement.scrollTop ? document.documentElement.scrollTop = 0 : document.body.scrollTop = 0;
  }

  onSubmit(e) {
    e.preventDefault();
  }

  onChange(key, value) {
    this.state.form[key] = value;
    this.handleSignBtnState();
    key === 'phone' && value.length === 11 && this.checkPhone(value);
    this.forceUpdate();
  }

  render() {

    const { showFrom, countDown, _disable, signState, bodyContent, isMobile } = this.state;

    return (
      <div className="sign-up">
        <div className="sign-up__banner" style={{ backgroundImage: `url(${isMobile ? mBanner : banner }` }}>
          <div className="sign-up__wrap">
            <div className="sign-up__head">
              <h1 className="title">四川省国际医学交流促进会</h1>
              <p className="des">新会员注册</p>
            </div>
            <div className="sign-up__func">
              {
                showFrom ? (
                  <div className="sign-up__func--fill">
                    <div className="title">申请个人会员</div>

                    <div className="sign-up__func--form">
                      <Form model={this.state.form} rules={this.state.rules} onSubmit={this.onSubmit.bind(this)}>

                        <Form.Item prop="phone">
                          <Input
                            value={this.state.form.phone}
                            placeholder="请输入手机号"
                            onChange={this.onChange.bind(this, 'phone')}
                          ></Input>
                        </Form.Item>

                        <Form.Item>
                          <div className="verification">
                            <Input
                              placeholder="请输入验证码"
                              value={this.state.form.code}
                              onChange={this.onChange.bind(this, 'code')}
                            ></Input>
                            <Button type="primary" disabled={_disable} onClick={this.handleGetCode.bind(this)}>{countDown}</Button>
                          </div>
                        </Form.Item>

                        <Form.Item className="full-width">
                          <Button type="primary" disabled={signState} onClick={this.handleSignUp.bind(this)}>注册/登录</Button>
                        </Form.Item>

                        <Form.Item className="clause">
                          <Checkbox.Group value={this.state.form.type} onChange={this.onChange.bind(this, 'type')}>
                            <Checkbox name="type"></Checkbox>
                          </Checkbox.Group>

                          <span className="txt">同意
                            <a href="http://join.scimea.cn/privacy" target="_blank">《SCIMEA会员协议条款》</a>
                          </span>

                        </Form.Item>

                      </Form>

                      <div className="sign-up__toggle">
                        <div className="sign-up__toggle--line">
                          <p className="des">微信注册/登录</p>
                        </div>
                        <img className="sign-up__toggle--wechat" src={wechat} onClick={this.handleSwith.bind(this)} />
                      </div>

                    </div>
                  </div>
                ) : (
                  <div className="sign-up__func--scan">
                    <div className="title">微信扫码注册/登录</div>

                    <div className="sign-up__func--scal">
                      <div className="inner">
                        <img className="code" src="https://api.mini.haimiantv.cn/member/common/qrcode.jpg" alt=""></img>
                      </div>

                      <div className="sign-up__toggle">
                        {
                          isMobile ? (
                            <div className="sign-up__toggle--tip">长按进入SCIMEA会员中心</div>
                          ) : (
                            <Fragment>
                              <div className="sign-up__toggle--line">
                                <p className="des">手机号注册</p>
                              </div>
                              <img className="sign-up__toggle--wechat" src={phoneUrl} onClick={this.handleSwith.bind(this)} />
                            </Fragment>
                          )
                        }

                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>

        <div className="sign-up__body">
          {
            bodyContent.map((item) => {
              return (
                <div className="sign-up__body--item" key={item.id}>
                  <h2 className="sign-up__body--title">{item.title}</h2>
                  <p className="sign-up__body--des">{item.des}</p>
                  <div className="sign-up__body--img-wrap">
                    <img className="sign-up__body--img" src={item.url} alt="" />
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="sign-up__company">
          <h2 className="sign-up__company--title">行业深度沉淀，3000+会员单位的信赖选择</h2>
          <p className="sign-up__company--des">已覆盖临床科研机构、医院、药企，医疗产业集群、行业协会、投资机构等</p>
          <div className="sign-up__company--img-wrap">
            <img className="sign-up__company--img" src={company} alt="" />
            <img className="sign-up__company--m-img" src={mCompany} alt="" />
          </div>
        </div>

        <div className="sign-up__slogan" style={{ backgroundImage: `url(${bottomBanner}` }}>
          <div className="sign-up__slogan--wrap">
            <h2 className="sign-up__slogan--title">一个有态度、有温度的医学之家</h2>
            <Button type="primary" onClick={this.handleClickJoin.bind(this)}>立即加入</Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  signState: state.getIn(['signUp', 'signState']),
  token: state.getIn(['signUp', 'token']),
  msg: state.getIn(['signUp', 'msg'])
});

const mapDispatch = (dispatch) => ({
  getSms(obj) {
    dispatch(actionCreators.getSms(obj));
  },

  changeUserState(flag) {
    dispatch(headerActionCreators.handleUserState(flag));
  }
});

export default connect(mapState, mapDispatch)(SignUp);
