import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import Stepper from 'react-stepper-horizontal';
import iconTip from './img/tip.svg';
class StepHead extends Component {

  constructor(props) {
    super(props);

    this.state = {
      steps: [
        { title: '填写会员申请表', icon: iconTip, },
        { title: '等待会员资料审核', icon: iconTip, },
        { title: '成功开通会员', icon: iconTip, }
      ],
      defaultColor: "#BFCBD9",
      activeColor: "#2096FA",
      activeTitleColor: "#2096FA",
      completeBarColor: "#2096FA",
      titleFontSize: 14,
      circleFontSize: 14,
    }
  }

  render() {

    const { step } = this.props;
    const { steps, defaultColor, activeColor, activeTitleColor, circleFontSize, titleFontSize } = this.state;

    return (
      <div className="step-head">
        <Stepper
          steps={ steps }
          activeStep={ step }
          size={34}
          defaultColor={defaultColor}
          activeColor={activeColor}
          activeTitleColor={activeTitleColor}
          circleFontSize={circleFontSize}
          titleFontSize={titleFontSize}
        />
      </div>
    )
  }
}

export default connect(null, null)(StepHead);