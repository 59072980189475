import * as constants from './constants';
import { Message } from 'element-react';
import http from '../../../utils/request';

const payData = (result) => ({
  type: constants.GET_PAY,
  phone: result.phone,
  name: result.name,
  date: result.date,
  money: result.money,
  years: result.years,
  qrcode: result.qrcode,
  order_no: result.order_no,
});

const payStatus = (result) => ({
  type: constants.CHECK_PAY,
  status: result.status
})

export const getPayData = (obj) => {
  return (dispatch) => {
    http('post', 'member/pc/pay', obj).then((res) => {
      if(res.code===200){
        dispatch(payData(res.data))
      }else{
        Message({
          type: 'error',
          message: res.msg,
          duration: 3000,
        })
      }
    })
  }
};

export const checkPay = (obj) => {
  return (dispatch) => {
    http('post', 'member/pc/find', obj).then((res) => {
      if(res.code===200){
        dispatch(payStatus({status:1}))
      }
    })
  }
};