import { fromJS } from "immutable";
import * as constants from './constants';


const defaultState = fromJS({
  profile: [],
  comms: [],
  head: [],
  person: []
});

const getInitProfile = (state, action) => {
  return state.merge({
    profile: fromJS(action.profile),
    comms: fromJS(action.comms),
    head: fromJS(action.head),
    person: fromJS(action.person),
  });
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.GET_PROFILE:
      return getInitProfile(state, action);
    default:
      return state;
  }
}