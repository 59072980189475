import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { Upload, Message } from 'element-react';
import cookie from 'react-cookies';

class UploadComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: '',
    };
  }

  handleAvatarScucess(res, file) {
    this.setState({ imageUrl: URL.createObjectURL(file.raw) });
  }

  beforeAvatarUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    const _type = file.type.split('/')[0];
    if (_type !== "image") {
      Message('请上传图片格式的文件');
    }

    if (!isLt2M) {
      Message('上传头像图片大小不能超过 2MB!');
    }
    return  isLt2M && _type;
  }

  componentWillReceiveProps(nextProps) {
    const { index } = this.props;
    nextProps.imgUrl && nextProps.imgUrl.length > 0 && this.setState({ imageUrl: nextProps.imgUrl[index-1].url });
  }

  componentDidMount() {}

  render() {

    const { imageUrl } = this.state;
    const { desc, index } = this.props;
    const token = cookie.load('token');

    return (
      <div className="upload">
        <Upload
          className="avatar-uploader"
          action="https://api.mini.haimiantv.cn/member/pc/file"
          data={{ token, "is_idcard": 2, "id": index }}
          showFileList={false}
          onSuccess={(res, file) => this.handleAvatarScucess(res, file)}
          beforeUpload={file => this.beforeAvatarUpload(file)}
          listType="picture"
        >
          {imageUrl ? <img src={imageUrl} className="avatar" /> : <i className="el-icon-plus avatar-uploader-icon"></i>}
        </Upload>
        <div className="el-upload__text">{ desc }</div>

      </div>
    )
  }
}

export default connect(null, null)(UploadComponent);