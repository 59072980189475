import { fromJS } from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  codeImg: '',
});

const handleGetPay = (state, action) => {
  return state.merge({
    codeImg: fromJS(action.codeImg),
  });
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case constants.GET_CODE:
      return handleGetPay(state, action);
    default:
      return state;
  }
}