import * as constants from './constants';
import http from '../../../utils/request';

const handleSort = (result) => ({
  type: constants.GET_SORT,
  sort: result
});

const handlePrivence = (result) => ({
  type: constants.GET_PROVINCE,
  province: result,
});

const handleCounty = (result) => ({
  type: constants.GET_COUNTRY,
  county: result,
});

const handleCommon = (result) => ({
  type: constants.GET_SECOND,
  degree: result.degree,
  education: result.education,
  nation: result.nation,
  political: result.political,
});

const handleCommon2 = (result) => ({
  type: constants.GET_THIRD,
  technicals: result.technicals,
  administrative: result.administrative,
  social: result.social
});

const handleDetail = (result) => ({
  type: constants.GET_INIT,
  detaile: result
});

export const getInitData = (obj) => {
  return (dispatch) => {
    http('post', 'member/index/comms', ApiSecurity.encryptParams({})).then((res) => {
      if (res.code === 200) {
        const result = JSON.parse(ApiSecurity.decrypt(res.data));
        // console.log('sort result', result);
        dispatch(handleSort(result));
      }
    })
  }
};

export const getInitProvince = (obj) => {
  return (dispatch) => {
    http('post', 'member/index/area', ApiSecurity.encryptParams(obj)).then((res) => {
      // console.log('provience', JSON.parse(ApiSecurity.decrypt(res.data)));
      const result = JSON.parse(ApiSecurity.decrypt(res.data));
      dispatch(handlePrivence(result));
    });
  }
};

export const getInitCounty = (obj) => {
  return (dispatch) => {
    http('post', 'member/index/area', ApiSecurity.encryptParams(obj)).then((res) => {
      const result = JSON.parse(ApiSecurity.decrypt(res.data));
      dispatch(handleCounty(result));
    });
  }
};

export const getInitCommon = () => {
  return (dispatch) => {
    http('post', 'member/index/second', ApiSecurity.encryptParams({})).then((res) => {
      if (res.code === 200) {
        const result = JSON.parse(ApiSecurity.decrypt(res.data));
        dispatch(handleCommon(result));
      }
    })
  }
}

export const getInitCommon2 = () => {
  return (dispatch) => {
    http('post', 'member/index/third', ApiSecurity.encryptParams({})).then((res) => {
      if (res.code === 200) {
        const result = JSON.parse(ApiSecurity.decrypt(res.data))
        // console.log('common third', result);
        dispatch(handleCommon2(result));
      }
    })
  }
}

export const getInitDetaile = (obj) => {
  return (dispatch) => {
    http('post', 'member/pc/detail', obj).then((res) => {
      if (res.code === 200) {
        const result = res.data;
        console.log('result', result);
        dispatch(handleDetail(result));
      }
    });
  }
}