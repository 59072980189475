import * as constants from './constants';
import http from '../../../utils/request';
import { Message } from 'element-react';

const signUp = (result) => ({
  type: constants.SIGN_UP,
  token: result.data.token,
  msg: result.msg
});

const handleSms = (result) => ({
  type: constants.GET_SMS,
  signData: true
})

export const handleSignUp = (obj) => {
  return (dispatch) => {
    http('post', 'member/pc/check_phone', obj).then((res) => {
      if (res.code === 200) {
        dispatch(signUp(res));
      } else {
        Message({
          type: 'error',
          message: res.msg,
          duration: 3000,
        });
      }
    });
  }
};


export const getSms = (obj) => {
  return (dispatch) => {
    http('post', 'member/pc/sms', obj).then((res) => {
      if (res.code === 200) {
        dispatch(handleSms(res));
      } else {
        Message({
          type: 'error',
          message: res.msg,
          duration: 3000,
        });
      }
    });
  }
}