import * as constants from './constants';

const userState = (result) => ({
  type: constants.USER_STATE,
  userState: result
});

export const handleUserState = (flag) => {
  return (dispatch) => {
    dispatch(userState(flag))
  }
}