import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <p className="copyright">Copyright © 2009-2021 四川省国际医学交流促进会. All rights reserved 
          <a href="http://www.beian.miit.gov.cn" target="_blank"> 蜀ICP备19011649号-1</a>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002003733" target="_blank">川公网安备51019002003733号</a></p>
      </div>
    )
  }
}

export default connect(null, null)(Footer)