import * as constants from './constants';
import http from '../../../utils/request';


const getData = (result) => ({
  type: constants.GET_CODE,
  codeImg: result.img,
});


export const getCodeUrl = (obj) => {
  return (dispatch) => {
    http('post', 'member/pc/qrcode', obj).then((res) => {
      const { data } = res;
      dispatch(getData(data))
    })
  }
};