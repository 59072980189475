import { combineReducers } from 'redux-immutable';
import { reducer as signUpReducer } from '../pages/signUp/store';
import { reducer as appReducer } from '../pages/step-1/store';
import { reducer as searchReducer } from '../pages/step-1/components/search/store';
import { reducer as payReducer } from '../pages/step-2/store';
import { reducer as paysuccessReducer } from '../pages/step-3/store';
import { reducer as profileReducer } from '../pages/profile/store';
import { reducer as userReducer } from '../common/header/store';
import { reducer as formReducer } from '../pages/form/store';

const reducer = combineReducers({
  signUp: signUpReducer,
  app: appReducer,
  search: searchReducer,
  pay: payReducer,
  paysuccess: paysuccessReducer,
  profile: profileReducer,
  user: userReducer,
  form: formReducer,
});

export default reducer;