import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import searchImg from '../../assets/search.svg';
import PopupComponent from '../overlay/index';
import { actionCreators } from './store';

class Search extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showView: false,
      value: '',
      id: ''
    }
    this.inputRef = React.createRef();
  }

  componentDidMount() {

    const { getInitUnit, type, getDepartment } = this.props;

    if (type === 'unit') {
      getInitUnit({ "name": "" });
    } else {
      getDepartment({ "name": "", "type": 2 });
    }

    document.addEventListener('click', this.handleDocumentClick.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick.bind(this));
  }

  // 点击弹框以外的地方弹框消失
  handleDocumentClick(e) {
    const { value } = this.state;
    if (!this.inputRef.current) {
      return;
    }
    // 点击弹框以内，不关闭， 点击弹框以外， 关闭
    if (!this.inputRef.current.contains(e.target) && this.inputRef.current !== e.target) {
      this.setState({ showView: false });
    }
  }

  // 传递信息给父组件
  handleInfo = (id) => {
    const { type } = this.props;
    const data = {
      type,
      id
    }
    this.props.getChildInfo(data);
  }

  // 阻止冒泡
  stopPropagation(e) {
    e.nativeEvent.stopImmediatePropagation();
  }

  // 点击input
  handleClick(e) {
    this.stopPropagation(e);
    const { showView } = this.state;
    this.setState({ showView: !showView });
  }

  // 输入内容
  handlChange(e) {
    const value = e.target.value;
    const { getInitUnit, type, getDepartment } = this.props;
    this.setState({ value });

    type === 'unit' ? getInitUnit({ "name": value }) : getDepartment({ "name": value, "type": 2 });

    if (value === '') {
      this.handleInfo('');
    }
  }

  // 点击结果
  handleClickItem(e) {
    this.stopPropagation(e);
    const val = e.currentTarget.dataset.jsVal;
    const id = e.currentTarget.dataset.jsId;
    this.setState({ value: val, id, showView: false });
    this.handleInfo(id);
  }

  // 获取来自子组件的值
  handleGetMsg(flag) {
    this.setState({ 'value': flag.name });
    this.handleInfo(flag.id)
  }

  render() {

    const { showView } = this.state;
    const { datas, label, type, showVal } = this.props;

    return (
      <div className="search">
        <div className={`search__content`}>
          <div className="search-input el-input">
            <i className="el-input__icon search-input__icon">
              <img className="icon" src={searchImg} alt=''></img>
            </i>
            <input
              placeholder={`请选择${label}`}
              type="text"
              className="el-input__inner"
              onClick={this.handleClick.bind(this)}
              onChange={this.handlChange.bind(this)}
              value={this.state.value || showVal}
            />
          </div>
          <div className="search-input__error">请输入{label}</div>

          <div className={`search-autoComplete-suggestion ${showView ? "showView" : ""}`} ref={this.inputRef}>
            {
              datas ? (
                <div className="el-box">
                  <div className="el-scrollbar">
                    <ul className="el-scrollbar__view">
                      {
                        datas.map((item, index) => {
                          return (
                            <li data-js-val={item.get('text') || item.get('des')} data-js-id={item.get('val') || item.get('id')} key={index} onClick={this.handleClickItem.bind(this)}>{item.get('text') || item.get('des')}</li>
                          )
                        })
                      }
                    </ul>
                  </div>
                  {
                    type === 'department' ? (
                      <div>
                        {
                          datas && datas.size === 0 ? (
                            <div className="notice center">请输入关键词搜索</div>
                          ) : null
                        }
                      </div>
                    ) : (
                      <div>
                        {
                          datas && datas.size > 0 ? (
                            <div className="add-bar">没有您想要的{label}?
                              <span className="ts">
                                <PopupComponent getMsg={this.handleGetMsg.bind(this)} desc="点击添加" />
                              </span>
                            </div>
                          ) : (
                            <div className="notice center">请输入关键词搜索</div>
                          )
                        }

                      </div>
                    )
                  }
                </div>
              ) : (
                <div className="notice">请输入关键词搜索</div>
              )
            }

          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({});

const mapDispatch = (dispatch) => ({
  getInitUnit(obj) {
    dispatch(actionCreators.getInitUnit(obj));
  },

  getDepartment(obj) {
    dispatch(actionCreators.getInitDeparty(obj));
  }
});

export default connect(mapState, mapDispatch)(Search);